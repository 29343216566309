/*--------------------------------------------------------------
# FILES SYSTEM
--------------------------------------------------------------*/

.file-component {
  color: #4e4e4e;
  overflow: auto;
}
.table{
  transform:scale(0.95);
}
.row-style{
  width: 80%;
}
.title-size{
  width:37.5%;
}
.file-component::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.file-component::-webkit-scrollbar-thumb {
  background-color: #35A9E0;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  opacity: 1;
  border-radius: 24px;
}

.file-component::-webkit-scrollbar-track {
  background-color: #fff;
}


.file-component .table-responsive {
  overflow: inherit;
}

.file-component .readOnly {
  border: none;
  /*background-color: #f0f3f4;*/
}

.file-component .table-responsive thead {
  background-color: #f9f9f9;
}


.file-component .table thead th {
  vertical-align: bottom;
  border-bottom: 0px solid #dee2e6;
  font-size: 15px;
}

.file-component h2 {
  margin-bottom: 30px;
}

.file-component table h2 {
  margin-bottom: 0px;
  text-align: left;
  font-size: 28px;
}

.file-component thead:first-of-type th {
  border-top: 0px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 25px 2rem;
}

.file-component thead:first-of-type tr {
  border-radius: 0.25rem;
}

.file-component input {
  cursor: pointer;
  text-align: left;
}

.modal .table-hover tbody tr:hover {
  background-color: #fff;
}

.file-component td[colspan]{
  cursor: pointer;
}

.file-component .table {
  -webkit-box-shadow: 0 7px 14px 0 rgba(59, 65, 94, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  box-shadow: 0 7px 14px 0 rgba(59, 65, 94, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  background-color: #fff;
  border-radius: 0.25rem;
  text-align: left;
}

.file-component input.readOnly {
  background-color: #fff;
  border: none;
  padding: 0px;
}

.file-component input {
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  background-color: #fff;
  color: #38a9e0;
  padding: 5px 10px;
  font-weight: 600;
}

.links-divider {
  position: relative;
  bottom: 1px;
}

.table-responsive {
  overflow-y: hidden;
  padding: 10px 15px;
}

.table-responsive thead {
  background-color: #f1f3f4;
  text-align: left;
}

.table-responsive thead.table-title {
  background-color: #fff;
  border-radius: 0.25rem !important;
}

.file-component.title {
  /*width: 87%;*/
  resize: both;
  margin-bottom: 20px;
}

.file-component p {
  margin-bottom: 0rem;
  font-size: 16px;
  overflow: hidden;
  cursor: pointer;
}

.to-hide {
  display: none;
}

.to-show {
  display: block;
}

.file-component.description {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  /*text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;*/
}

.column-width {
  width: 30%;
}

.overflow-visible {
  white-space: initial;
}

.table-responsive tr td:first-of-type, .table-responsive tr th:first-of-type {
  padding-left: 2rem !important;
}

.table-responsive tr td:nth-last-of-type(1), .table-responsive tr th:nth-last-of-type(1), .table-responsive tr td:nth-last-of-type(2), .table-responsive tr th:nth-last-of-type(2) {
  text-align: center;
}

.file-component .table-title ul {
  display: flex;
  padding-left: 0px;
  margin-bottom: 0px;
  overflow-x: auto;
  width: 600px;
  padding-bottom: 2px;
}

.file-component .table-title li {
  padding: 0 15px;
  border-left: 1px solid #dcdcdc;
  font-size: 18px;
  font-weight: 600;
  color: #35a9e0;
  cursor: pointer;
  display: -webkit-box;
  white-space: nowrap;
}

.file-component .table-title li.current-folder {
  color: #4f4f4f;
}

.file-component .table-title li:first-of-type {
  padding-left: 0px;
  border-left: none;
}

.file-component .table-title li img {
  margin-right: 10px;
}

.file-component .new-folder, .file-component .empty-trash-button {
  background-image: url(./../images/new-folder-icon.svg);
  background-size: 20px;
  display: block;
  background-repeat: no-repeat;
  background-position: left;
  background-position-x: 12px;
  background-position-y: 8px;
  background-color: #fff;
  float: right;
  border: 1px solid #cdcdcd;
  padding: 5px 10px;
  padding-left: 40px;
}

.file-component .empty-trash-button {
  margin-right: 35px;
}

.file-component .new-folder {
  margin-right: 10%;
}

.file-component .empty-trash-button {
  background-image: url(./../images/trash-icon.svg);
  background-size: 16px;
}

.file-component .trash-button {
  background-size: inherit;
  display: block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: left;
  background-color: #fff;
  float: right;
  border: none;
}

.trash-empty-button {
  background-image: url(./../images/trash-empty-icon.svg);
}

.trash-full-button {
  background-image: url(./../images/trash-icon.svg);
}

.file-component .table-responsive tr th:first-of-type {
  font-weight: 500;
  width: 80px;
}

.file-component .trash-button:focus {
  border: none;
}

.file-component tbody input {
  font-weight: 500;
  padding: 0px;
}

.file-component select {
  padding: 7px 10px;
  border-radius: 5px;
  border: 1px solid #dcdcdc;
  font-weight: 600;
  margin-right: 8px;
  margin-left: 20px;
}

input:focus+label {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}

.file-component input[type=checkbox], .file-component input[type=checkbox]:focus {
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.5);
  /* IE */
  -moz-transform: scale(1.5);
  /* FF */
  -webkit-transform: scale(1.5);
  /* Safari and Chrome */
  -o-transform: scale(1.5);
  /* Opera */
  transform: scale(1.5);
  padding: 10px;
}

.file-component .table th {
  padding: 15px 0;
}

.file-component .table td {
  padding: 20px 0 0;
  line-height: 30px;
}

.table .selected-row td {
  border-top: 1px solid #35a9e0;
}

.file-component .table td img {
  height: 20px;
  margin-right: 20px;
  margin-bottom: 6px;
}

.action-text {
  line-height: 2;
}

.file-component .selected-row, .file-component .selected-row:hover {
  background-color: #35a9e0;
  color: #fff;
}

.file-component .table-hover tbody tr.selected-row:hover {
  background-color: #35a9e0;
}

.selected-row img {
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(346deg) brightness(180%) contrast(100%);
}

.file-component .table-hover tbody tr:hover {
  background-color: transparent;
}

.file-component .selected-row input {
  border: none;
  background-color: #35a9e0;
  color: #ffffff;
}

.custom-control-label::before {
  position: absolute;
  top: -1.2rem;
  left: -0.2rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #fff;
}

.custom-checkbox {
  float: left;
  margin-bottom: 0.8rem !important;
  padding-left: 0rem;
  position: relative;
  top: 5px;
}

.custom-control-input {
  position: absolute;
  z-index: 1;
  opacity: 0;
}

.custom-control-label::after {
  position: absolute;
  top: -1.2rem;
  left: -0.2rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.form-group input:not(:placeholder-shown)~label, input:focus+label {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background-color: #fff;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(346deg) brightness(180%) contrast(100%);
  background-size: 10px;
}

.file-component.description {
  color: #4e4e4e;
}

.file-component .toolbar-select .css-va7pk8 {
  overflow: hidden;
}

.file-component .toolbar-select {
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 140px;
  display: block;
  float: left;
  height: 36px;
  border: 1px solid #cacaca;
  background-color: #fff;
  border-radius: 5px;
  margin: 0 10px 0 20px;
}

.toolbar-select .css-11unzgr {
  background-color: #4e4e4e;
  color: #fff;
  border-radius: 5px;
  width: 160px;
  position: relative;
  right: 20px;
  bottom: 45px;
  font-weight: 500;
  -webkit-box-shadow: 0 7px 14px 0 rgba(59, 65, 94, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  box-shadow: 0 7px 14px 0 rgba(59, 65, 94, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  text-align: left;
}

.toolbar-select .css-15k3avv {
  height: 50px;
}

.css-11unzgr div {
  cursor: pointer;
}

.toolbar-select .css-z5z6cw, .toolbar-select .css-z5z6cw:hover, .toolbar-select .css-wqgs6e, .toolbar-select .css-v73v8k:active, .toolbar-select .css-z5z6cw:active, .toolbar-select .css-wqgs6e:active {
  background-color: #38a9e0;
}

.toolbar-select .css-va7pk8 {
  color: #4e4e4e;
}

.toolbar-select .css-162g8z5 {
  min-height: 34px;
  height: 34px;
  border: none;
  border-radius: 5px;
}

.file-component .toolbar-select .css-1rtrksz {
  height: inherit;
  padding: 0;
}

.file-component .toolbar-select .css-1aya2g8, .file-component .toolbar-select .css-2o5izw {
  min-height: 35px;
  height: 35px;
}

.file-options-icon {
  cursor: pointer;
  margin-left: 20px;
}

.popover {
  display: block;
  padding: 10px;
  background-color: #38a9e0;
  color: #fff;
  min-width: 330px;
  max-width: 360px;
  position: relative;
  right: 100px;
  top: 5px !important;
  border-radius: 0px;
  padding: 5px 0px;
}

.popover .arrow:after, .popover .arrow:before, .popover .arrow {
  border-bottom-color: #38a9e0;
  border-top-color: #38a9e0;
}

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-bottom-color: white;
    border-top-color: white;
    border-right-color: #38a9e0;
    border-left-color: #38a9e0;
    left: 0px;
}

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-bottom-color: white;
    border-top-color: white;
    border-right-color: #38a9e0;
    border-left-color: #38a9e0;
    left: 0px;
}

.popover a {
  text-align: left;
  color: #fff;
  display: block;
  font-size: 15px;
}

.popover a:hover {
  text-decoration: none;
}

.go-button {
  float: left;
}

.popover-inner div {
  padding: 5px 12px;
}

.popover-inner div:hover {
  background-color: #fff;
}

.popover-inner div:hover a {
  color: #38a9e0;
}

.file-columns-titles tr th:first-of-type {
  padding: 20px 0 0;
}

.file-columns-titles .custom-checkbox {
  margin-bottom: 0.8rem !important;
}

.icon-search-loading {
  display: block;
  width: 100%;
  margin: 0 auto;
}

.new-folder-row.icon-search-loading {
  display: inline-block;
  text-align: center;
  height: 40px;
  width: 100%;
  margin: 0 auto;
  position: sticky;
  left: 47%;
  padding-top: 6px;
}

.close-new-row svg {
  height: 17px;
  overflow: hidden;
  cursor: pointer;
  vertical-align: middle;
  color: #96002f;
}

.file-component .table-title ul::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
  position: relative;
  top: 10px;
}

.file-component .table-title ul::-webkit-scrollbar {
  width: 20px;
  height: 6px;
  background-color: #F5F5F5;
  position: relative;
  top: 10px;
}

.file-component .table-title ul::-webkit-scrollbar-thumb {
  background-color: #38a9e0;
  border: 1px solid #38a9e0;
  position: relative;
  top: 10px;
}

.file-component .table-title ul::-webkit-scrollbar-thumb:hover {
  background-color: #56baec;
  border: 1px solid #56baec;
  position: relative;
  top: 10px;
}

.modal .file-component .table-title ul {
  width: 800px;
}

.modal .files-modal .alert-danger {
  margin-bottom: 20px;
}

.files-modal .close-new-row svg {
  position: relative;
  right: 40px;
  top: 10px;
}

.svg-inline--fa.fa-w-11 {
  width: 0.6875em;
}

@media(max-width:1220px) {
  .file-component .table-title ul {
    width: 750px;
  }
}

@media(max-width:992px) {
  .file-component {
    max-width: 100%;
  }
  .file-component .new-folder {
    margin-right: 15px;
  }
  .file-component .table-title ul {
    width: 550px;
  }
  .modal .file-component .table-title ul {
    width: 700px;
  }
}

@media (max-width: 768px) {
  .file-component {
    max-width: 100%;
  }
  .file-component .table td img {
    margin-right: 15px;
  }
  .file-component input.readOnly {
    max-width: 110px;
  }
  .modal .file-component .table-title ul {
    width: 500px;
  }
}

@media (max-width: 600px) {
  .file-component .toolbar-select {
    width: 110px;
  }
  .file-component .table-responsive tr th:first-of-type {
    width: 50px;
  }
  .file-component .table-responsive tr th:first-of-type, .file-component .table-responsive tr td:first-of-type {
    padding-left: 0.8rem !important;
  }
  .file-component input.readOnly {
    max-width: 120px;
  }
  .file-component .table .file-columns-titles th:nth-of-type(3), .file-component .table .file-columns-titles th:nth-of-type(4), .file-component tbody td:nth-of-type(3), .file-component tbody td:nth-of-type(4) {
    display: none;
  }
  .file-component .table td img {
    margin-right: 4px;
  }
  .file-component .new-folder {
    background-position-x: 11px;
    padding: 5px 0px;
    width: 43px;
    color: transparent;
    margin-right: 10px;
  }
  .file-component thead:first-of-type th {
    padding: 25px 0.5rem;
  }
  .App .file-component .css-1ep9fjw {
    left: 3px;
  }
  .file-options-icon {
    margin-left: 5px;
  }
  .file-component .table-title ul {
    width: 300px;
  }
  .modal .file-component .table-title ul {
    width: 400px;
  }
}

@media (max-width: 450px) {
  .file-component input.readOnly {
    max-width: 80px;
  }
  .file-component .table-title ul {
    width: 260px;
  }
  .modal .file-component .table-title ul {
    width: 300px;
  }
}

@media(max-width: 350px) {
  .file-component .toolbar-select {
    margin: 0 5px 0 10px;
  }
}


.timeline{
  padding-right: 4%
}