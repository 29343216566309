.OverridesForm {
   padding: 20px;
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
   align-items: center;
}

.OverridesForm .content-inputs {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
   
}

.OverridesForm .url-input {
    width: 80%;
    margin-top: 15px
}

.OverridesForm .content-loc-menu {
    width: 70%;
    display: flex;
    align-items: center;
    margin-top: 15px
}

.OverridesForm .option-select {
    width: 100%;
    height: 39px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    background-color: #ffff;
    align-items: center;
    margin-top: 15px
}

.OverridesForm .css-1rtrksz, .sortable-item .css-1rtrksz {
    height: 0px;
    left: 0px; 
}

.OverridesForm .container-option-select {
    width: 30%
}

.OverridesForm .content-submit{
    width: 10%
}
.OverridesForm .content-submit button {
    width: 100%
}


/* MODAL */

.overrideForm .modal-content{
    border-radius: 20px;
    border: 0px;
    margin: 0 auto;
    width: 80%;
    padding: 20px 20px 35px 20px;
}

.overrideForm .modal-header{
    border: none;
}

.overrideForm .modal-header .modal-title{
    width: 100%;
    text-align: center;
    margin-top: 10px;
    font-weight: bold;
    font-size: 1.5em;
}

.overrideForm div.modal-body{
    background-color: #fff !important;
}

.overrideForm div.modal-footer{
    border: none;
}

.overrideForm .OverridesForm input[type="text"]{
    border-radius: 0px;
    height: 50px;
}

.overrideForm .btnCancelOverride{
    background-color: transparent;
    color: #000;
    border: none;
}

.overrideForm .btnCancelOverride:hover, .btnCancelOverride:focus, .btnCancelOverride:active{
    background-color: transparent !important;
    color: #000 !important;
    border: none !important;
    outline: 0 !important;
    box-shadow: none;
}

.OverridesList .option-select-override, .OverridesList .DropdownMenu{
    display: inline-block;
    width: 82%;
}

.OverridesList #location-holder{
    width: 90%;
    display: inline-block;
}

.OverridesList .pencil_override{
    float: right;
    margin-left: 5px;
    margin-top: 10px;
}

.OverridesList table .col-header{
    vertical-align: top;
}

.overrideForm .btnCreateCurrentOverride{
    background-color: #35A9E0 !important;
    border: none;
}