body {
    font-size: 1rem !important;
}
@media screen and (max-width:1300px) {
    body {
        font-size: .9rem !important;
    }
}
@media screen and (max-width:768px) {
    body {
        font-size: .8rem !important;
    }
}
@media screen and (max-width:578px) {
    body {
        font-size: .7rem !important;
    }
}