/*--------------------------------------------------------------
# STATS BOXES
--------------------------------------------------------------*/
.stats-boxes{
  width:98.5%;
}
.stats-boxes .stats-box {
    text-align: center;
  }
  
  .stats-boxes .stats-box .card {
    min-height: 220px;
    padding: 1.11rem;
  }
  
  .stats-boxes .stats-box.category-distribution .card {
    min-height: 220px;
    padding: 1.0rem;
  }

  body .card-body,
  .sortable-item .card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0.75rem 0;
  }
  
  body .card {
    display: block;
  }
  
  .App .stats-box h5 {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
  }
  
  .stats-boxes.fixed .stats-box h4 {
    font-size: 10px;
    margin-top: -5px;
  }
  .stats-boxes.fixed .stats-box .minimum-text {
    margin-top: -10px;
  }
  .stats-boxes.fixed .stats-box h5 {
    font-size: 14px;
  }

  .stats-box h4 {
    font-size: 14px;
    font-weight: 700;
  }

  .stat-box .minimum-text {
    margin-bottom: 0;
  }
  
  .stats-box h2 {
    font-size: 54px;
    font-weight: 700;
  }
  
  .text-negative {
    font-size: 77px;
    font-weight: 700;
    margin-top: 15px;
    padding-bottom: 5px;
    line-height: 1.1;
  }

  .sentiment-distribution canvas {
    margin: 0 auto;
    margin-top: 9%;
    margin-left:-4%;
  }
  .category-distribution canvas {
    margin: 0 auto;
    margin-top: 2%;
  }
  
  .text-positive {
    color: #888888;
  }
 
  .up-icon {
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid green;
    display: inline-block;
    margin-right: 10px;
    position: relative;
    top: -10px;
  }
  
  .down-icon {
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #f00;
    display: inline-block;
    margin-right: 10px;
    position: relative;
    top: -2px;
  }

  .stats-box hr {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  span.percentage-change {
    font-size: 32px;
    margin-right: 10px;
  }
  
  .this-month {
    display: inline-block;
    text-align: left;
    font-size: 10px;
  }