/*--------------------------------------------------------------
# GENERAL STYLES
--------------------------------------------------------------*/

.App h5 {
    font-size: 14px;
}

.input-group-append .btn,
.input-group-prepend .btn {
    z-index: 0;
}

.App a {
    color: #337ab7;
}

.App h3,
.sortable-item h3 {
    margin-top: 0;
    font-size: 18px;
    margin-bottom: 0;
}

.App {
    text-align: center;
}

body {
    background-color: #f1f3f4 !important;
    font-family: "Inter", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.modal-body {
    background-color: #f1f3f4 !important;
}

.modal-content .btn-primary {
    color: #fff;
    background-color: #2e6da4;
    border-color: #2e6da4;
}

body .container {
    padding: 2rem 0;
}

.pd-30 {
    padding-bottom: 30px;
}

.mb-30 {
    margin-bottom: 30px;
}

.icon-monthly-search-loading {
    display: flex;
    justify-content: center;
    padding: 18% 0;
}

@media (max-width: 768px) {
    .icon-monthly-search-loading {
        padding: 22% 0;
    }
}

.related-searches-not-found {
    padding: 50px 0px;
}

.refresh-icon {
    margin-left: 10px;
    height: 35px !important;
    cursor: pointer;
    padding: 4px;
    display: block;
    margin: 0 auto;
}

.pagination {
    display: inline-block;
    margin: 0 auto;
    -webkit-box-shadow: 0 7px 14px 0 rgba(59, 65, 94, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
    box-shadow: 0 7px 14px 0 rgba(59, 65, 94, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
    background-color: #fff;
}

.pagination li {
    background-color: #fff;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0.25rem;
}

.pagination li.active {
    background-color: #337ab7;
}

.pagination li.active a {
    color: #fff;
}

.pagination li a {
    color: #212529;
    text-decoration: none;
}

.pagination li:hover:not(.active) {
    background-color: rgb(241, 243, 244);
}

body .header-area .save-split-btn.btn-primary:not(:disabled):not(.disabled).active {
    min-width: 156px;
    margin-right: 0px;
    border-color: #337ab7;
}

.App .save-split-caret.btn-primary {
    color: #fff;
    background-color: #337ab7;
    border-color: #337ab7;
}

.save-split-btn.btn-primary:not(:disabled):not(.disabled).active:focus, .save-split-caret.btn-primary:not(:disabled):not(.disabled):focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #2a6699;
}

.save-split-caret.btn-primary:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #2a6699;
    border-color: #337ab7;
}

.save-dropdown-btn .dropdown-menu.show {
    transform: translate3d(15px, 44px, 0px) !important;
    border: none;
    margin-top: 5px;
}

body .header-area .dropdown-menu.show .btn-primary:not(:disabled):not(.disabled).active {
    margin-left: 0px;
    min-width: 125px;
    text-align: center;
    padding: .5rem 1rem;
}

.save-dropdown-btn .dropdown-menu.show {
    padding: 0px;
}

.modal .table-responsive {
    padding: 10px 0px;
}

.file-component .table-responsive .table-title tr th {
    border: none;
}

.save-split-caret.dropdown-toggle::after {
    right: 6px;
}

.modal .file-component .table-responsive tr th:first-of-type {
    width: 35px;
    padding: 20px 0;
}

.modal .file-component .table-responsive tr th:last-of-type {
    width: 10px;
}

.modal .file-component.title {
    width: 90%;
    resize: both;
    margin: 10px 0;
}

.modal .icon-search-loading .lds-css, .icon-search-loading .lds-css {
    width: 100%;
    margin: 0 auto;
}

.modal .icon-search-loading .lds-css, .icon-search-loading .lds-css {
    width: 35px;
    margin: 0 auto;
}


/*--------------------------------------------------------------
# @MEDIA QUERIES
--------------------------------------------------------------*/

@media (min-width: 768px) {
    .App .top-stories.card.ui-sortable-handle,
    .sortable-item .top-stories.card.ui-sortable-handle {
        max-width: 98.7%;
    }
}

@media (min-width: 992px) {
    .App .top-stories.card.ui-sortable-handle,
    .sortable-item .top-stories.card.ui-sortable-handle {
        max-width: 65.85%;
    }

    .App .knowledge-graph, .App .related-searches {
        margin-left: 20px;
        width: calc(33.333333% - 20px);
    }

    .App .knowledge-graph.knowledge-graph-description {
        height: auto;
    }

    body .header-area .btn-primary:not(:disabled):not(.disabled).active {
        min-width: 155px;
    }

    body .header-area .btn-danger:not(:disabled):not(.disabled).active {
        padding: 0.5rem 1rem;
    }
}

@media (min-width: 1024px) {
    .header-area.fixed,
    .header-area.up {
        position: fixed;
        width: 100%;
        z-index: 99;
        height: 11rem;
    }

    .fixed .container {
        padding: 0.9rem 0;
    }

    .fixed .pd-30.mb-30 {
        margin-bottom: 0px;
        padding-bottom: 0px;
        position: relative;
        top: 15px;
    }

    .fixed .header-input-group {
        display: none;
    }

    .stats-box {
        display: flex
    }

    .stats-boxes.fixed {
        position: fixed;
        z-index: 99;
        width: 100%;
    }

    .stats-boxes.fixed .row {
        float: right;
    }

    .stats-boxes.fixed .col-md-4 {
        width: 380px !important;
        max-width: 260px;
        float: right;
        flex: 0 0 50%;
    }

    .fixed.stats-boxes .stats-box {
        height: 100px;
    }

    .stats-boxes.fixed .container {
        padding: 8px 2px;
    }

    .fixed.stats-boxes .stats-box .card {
        min-height: 110px;
        height: 120px;
        padding: 0px;
        padding-top: 8px;
    }

    .fixed .text-negative {
        font-size: 42px;
        margin-bottom: 2px;
        margin-top: 10px;
    }

    .fixed .container.ranking {
        padding-top: 0%;
        padding-left: 0.5%;
    }

    .header-area.fixed,
    .stats-boxes.fixed {
        animation-delay: 0ms;
        animation-name: fadeInDown;
        -webkit-animation-duration: 0.6s;
        animation-duration: 0.6s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }

    .header-area.up,
    .stats-boxes.up {
        animation-delay: 0ms;
        animation-name: fadeOutUp;
        -webkit-animation-duration: 0.6s;
        animation-duration: 0.6s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }

    .stats-box.visible {
        display: flex;
    }

    .stats-box.hidden {
        display: none;
    }
}

@media (min-width: 1200px) {
    body .container {
        max-width: 1170px;
    }

    body .header-area .btn-primary:not(:disabled):not(.disabled).active {
        font-size: 18px;
    }

    body .header-area .btn-primary:not(:disabled):not(.disabled).active {
        font-size: 18px;
        width: 100%;
        min-width: 155px;
        margin-right: 55px;
        margin-left: 15px;
    }

    .category-distribution canvas {
        margin-top: -2%;
    }
}

@media (min-width: 1190px) {
    .category-distribution canvas {
        margin-top: 1%;
    }
}

@media (min-width: 1500px) {
    body .container {
        max-width: 1300px;
    }

    .App .knowledge-graph {
        margin-top: 44px;
    }

    .App .fixed .knowledge-graph {
        margin-top: 24px;
    }


    body .header-area .btn-primary:not(:disabled):not(.disabled).active {
        font-size: 18px;
        width: 100%;
        min-width: 200px;
        margin-right: 55px;
        margin-left: 15px;
    }

}

@media (min-width: 576px) {
    body .modal-dialog {
        max-width: 900px;
    }

    .fixed .sentiment-distribution canvas, .fixed .category-distribution canvas {
        margin: 0 auto;
        margin-top: 0%;
        height: 80px !important;
        width: auto !important;
    }

    .fixed .category-distribution canvas {
        margin-top: -3%;
        height: 92px !important;
    }
}

@media (max-width: 4500px) {
    .App .knowledge-graph {
        margin-top: 44px;
        margin-bottom: 33px;
    }
}


@media (max-width: 1500px) {
    .App .container-select-sentiment,
    .sortable-item .container-select-sentiment {
        display: block;
        width: 55px;
        height: inherit;
    }

    .App .top-stories .select-category-image,
    .sortable-item .top-stories .select-category-image {
        width: 87%;
        padding: 0px 14%;
        position: relative;
        right: 3px;
    }

    .App .fixed .knowledge-graph {
        margin-top: 40px;
    }

    .change-password-cont {
        margin-top: -40px;
    }

    .App .rank-select .css-142w6gm,
    .sortable-item .rank-select .css-142w6gm {
        right: 9px;
    }

    .App .rankingTitles {
        font-size: 13px;
    }

    .ranking-title .text-left {
        padding-right: 0px;
        padding-left: 10px;
    }

    .ranking-title .text-left.col-6 {
        padding-right: 0px;
        padding-left: 18px;
    }

    .save-warning {
        min-width: 150px;
    }

    body .header-area .save-split-btn.btn-primary:not(:disabled):not(.disabled).active {
        min-width: 90px;
    }

    body .header-area .dropdown-menu.show .btn-primary:not(:disabled):not(.disabled).active {
        min-width: 113px;
    }

    .text-negative {
        font-size: 68px;
    }
}

@media (max-width: 1200px) {
    .button-sentiment,
    .sortable-item .button-sentiment {
        padding: 0px !important;
    }

    .dropdown-toggle.button-sentiment::after,
    .sortable-item .dropdown-toggle.button-sentiment::after {
        right: -22px;
    }

    .ranking-title .text-left:last-of-type {
        padding-left: 0px;
        text-align: center !important;
    }

    .App .css-1rtrksz,
    .sortable-item .css-1rtrksz {
        height: 70px;
    }

    .App .rank-select .css-1ep9fjw,
    .sortable-item .rank-select .css-1ep9fjw,
    .App .rank-select .css-1sjym57,
    .sortable-item .rank-select .css-1sjym57 {
        right: 0px;
    }

    .App .container-select-sentiment,
    .sortable-item .container-select-sentiment {
        width: 45px;
    }

    .App .rankingTitles {
        font-size: 10px;
    }

    .save-warning {
        margin-left: 5px;
    }

    .save-warning {
        min-width: 110px;
        margin-right: 0px;
    }

    .save-warning,
    .exportFileMessage {
        font-size: 10px;
    }

    .App .save-split-caret.btn-primary {
        margin-right: 10px;
    }

    body .header-area .save-split-btn.btn-primary:not(:disabled):not(.disabled).active {
        min-width: 123px;
    }

    .save-dropdown-btn .dropdown-menu.show {
        -webkit-transform: translate3d(0px, 44px, 0px) !important;
        transform: translate3d(0px, 44px, 0px) !important;
    }

    body .header-area .dropdown-menu.show .btn-primary:not(:disabled):not(.disabled).active {
        min-width: 95px;
        width: 148px;
    }

    .stats-box.col-md-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 20px;
    }

    .sentiment-distribution canvas {
        margin: 0 auto;
        margin-top: 3%;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    select,
    textarea,
    input {
        font-size: 16px;
    }
}

@media (max-width: 992px) {
    .stats-boxes .stats-box {
        margin-bottom: 20px;
    }

    body .card.ui-sortable-handle,
    .sortable-item .card.ui-sortable-handle {
        max-width: 100%;
    }

    button.view-details,
    .sortable-item button.view-details {
        white-space: pre-wrap;
        padding: 0px;
    }

    body .modal-body .table > thead > tr > th {
        padding: 5px;
    }

    body .modal-body {
        padding: 8px;
    }

    .modal-body {
        background-color: #f1f3f4 !important;
    }

    .header-area .button-header,
    .header-area .input-header {
        margin-bottom: 10px;
    }

    .filter-option img,
    .sortable-item .filter-option img {
        padding: 0px;
    }

    .header-area .btn-secondary,
    body .header-area .btn-primary:not(:disabled):not(.disabled).active {
        padding: 10px 10px;
    }

    input[type="text"],
    input[type="number"],
    textarea {
        font-size: 16px;
    }

    .App .container-select-sentiment,
    .sortable-item .container-select-sentiment {
        width: 50px;
    }

    .links-divider {
        display: none;
    }

    .file-component td a {
        display: block;
    }

    .header-input-group .row {
        width: 100%;
        margin-left: 0px;
    }

    body .header-area .btn-primary:not(:disabled):not(.disabled).active {
        margin-right: 0px;
        margin-left: 0px;
    }

    .App .rankingTitles {
        font-size: 12px;
    }

    .stats-boxes .stats-box .card {
        padding: 1.25rem 0.8rem;
    }

    .save-warning {
        margin-left: 0px;
    }

    .save-warning,
    .exportFileMessage {
        font-size: 12px;
    }

    .save-dropdown-btn {
        width: 103%;
    }

    .save-dropdown-btn .dropdown-menu.show {
        -webkit-transform: translate3d(0px, 47px, 0px) !important;
        transform: translate3d(0px, 47px, 0px) !important;
        width: 97%;
    }

    body .header-area .dropdown-menu.show .btn-primary:not(:disabled):not(.disabled).active {
        width: 100%;
    }

}

@media (max-width: 992px) and (min-width: 768px) {
    .sentiment-distribution canvas {
        height: 130px !important;
        width: 260px !important;
    }

    .category-distribution canvas {
        height: 150px !important;
        width: 250px !important;
    }

    .stats-box h2 {
        font-size: 38px;
    }

    .stats-boxes .stats-box .card {
        min-height: 200px;
    }
}

@media (max-width: 768px) {
    .dropdown-toggle.button-sentiment::after,
    .sortable-item .dropdown-toggle.button-sentiment::after {
        right: -20px;
    }

    body .card.ui-sortable-handle,
    .sortable-item .card.ui-sortable-handle {
        padding-left: 20px;
    }

    span.step {
        width: 1.6em;
        height: 1.6em;
        line-height: 1.6;
    }

    .App .rank-select .css-1rtrksz,
    .sortable-item .rank-select .css-1rtrksz {
        left: 4px;
    }

    .App .row-left {
        margin-right: -15px !important;
    }

    .App .container-select-sentiment,
    .sortable-item .container-select-sentiment {
        width: 42px;
    }

    .App .rank-select .css-1ep9fjw,
    .sortable-item .rank-select .css-1ep9fjw,
    .App .rank-select .css-1sjym57,
    .sortable-item .rank-select .css-1sjym57 {
        left: 2px;
        bottom: 1px;
    }

    .App .select-sentiment,
    .sortable-item .select-sentiment {
        right: 2px;
    }

    .result-score-editable {
        width: 80px;
    }

    .App .ranking-cards {
        padding-left: 0px;
        padding-right: 0px;
    }

    .header-area .container {
        padding: 1rem 0;
    }

    .App .fixed .knowledge-graph {
        margin-top: 10px;
    }

    .profile-menu-cont {
        position: relative;
        bottom: 6px;
    }

    body .header-area .btn-danger:not(:disabled):not(.disabled).active,
    body .header-area .btn-primary:not(:disabled):not(.disabled).active {
        font-size: 16px;
    }

    .ranking-title .text-left.col-6,
    .ranking-title .no-side-p.col-2,
    .ranking-title .text-left.col-2 {
        display: flex;
        flex-flow: column;
    }

    .ranking-title span.titleAlign {
        order: 2;
    }

    .ranking-title span:last-of-type {
        order: 1;
    }

    .save-dropdown-btn .dropdown-menu.show {
        -webkit-transform: translate3d(0px, 43px, 0px) !important;
        transform: translate3d(0px, 43px, 0px) !important;
        width: 96%;
    }
}

@media (max-width: 700px) {
    .App .css-1rtrksz,
    .sortable-item .css-1rtrksz {
        height: 50px;
    }

    .App .container-select-sentiment,
    .sortable-item .container-select-sentiment {
        width: 30px;
    }

    .App .rank-select .css-142w6gm,
    .sortable-item .rank-select .css-142w6gm {
        right: 6px;
    }
}

@media (max-width: 600px) {
    .alert-success table tr {
        display: grid;
        float: left;
    }

    .App .container-select-sentiment,
    .sortable-item .container-select-sentiment {
        width: 26px;
    }
}

@media (max-width: 580px) {
    body .row,
    .sortable-item .row {
        margin-right: 0px;
        margin-left: 0px;
        margin-bottom: 0px;
    }

    .ranking ul,
    .sortable-item ul {
        padding-right: 0px;
        padding-left: 0px;
    }

    .App .ranking h5,
    .sortable-item h5 {
        font-size: 11px;
    }

    .result-score,
    .sortable-item .result-score {
        font-size: 24px;
    }

    .bootstrap-select .button-sentiment,
    .sortable-item .button-sentiment {
        padding: 0px;
        position: relative;
        right: 17px;
    }

    .dropdown-toggle::after,
    .sortable-item .dropdown-toggle::after {
        right: 0px;
    }

    body .modal-body {
        padding: 8px;
    }

    .App .css-1ep9fjw,
    .sortable-item .css-1ep9fjw,
    .App .css-1sjym57,
    .sortable-item .css-1sjym57 {
        padding: 0px;
        position: relative;
        left: 4px;
    }

    .App .css-1rtrksz,
    .sortable-item .css-1rtrksz {
        height: 45px;
        padding: 0px;
    }

    .select-category img,
    .sortable-item .select-category img {
        padding: 0px 0px;
        width: 35px;
    }

    .App .rank-select .css-10nd86i,
    .sortable-item .rank-select .css-10nd86i,
    .App .rank-select .css-1sontr1,
    .sortable-item .rank-select .css-1sontr1 {
        width: 49px;
        position: relative;
        right: 22px;
    }

    .App .rank-select .css-1ep9fjw,
    .sortable-item .rank-select .css-1ep9fjw,
    .App .rank-select .css-1sjym57,
    .sortable-item .rank-select .css-1sjym57 {
        left: 1px;
    }

    .App .rank-select .css-va7pk8,
    .sortable-item .rank-select .css-va7pk8 {
        right: -2px;
    }

    .App .rank-select .css-142w6gm,
    .sortable-item .rank-select .css-142w6gm {
        right: 0px;
    }

    .App .css-va7pk8,
    .sortable-item .css-va7pk8,
    .App .css-142w6gm,
    .sortable-item .css-142w6gm {
        overflow: visible;
    }

    .select-category {
        position: relative;
        right: 3px;
    }

    .css-1sjym57 {
        display: block !important;
        padding: 0px !important;
        box-sizing: unset !important;
        padding: 0px;
        position: relative;
        left: 12px;
        bottom: 2px;
    }

    .App .rank-select .css-1sjym57,
    .sortable-item .rank-select .css-1sjym57 {
        bottom: 3px;
    }

    .App .rank-select .css-1rtrksz,
    .sortable-item .rank-select .css-1rtrksz {
        left: 0px;
    }

    .App .select-sentiment,
    .sortable-item .select-sentiment {
        right: 2px;
    }

    .App .select-category-col .css-1aya2g8,
    .sortable-item .select-category-col .css-1aya2g8,
    .App .select-category-col .css-2o5izw,
    .sortable-item .select-category-col .css-2o5izw,
    .App .select-category-col .css-162g8z5,
    .sortable-item .select-category-col .css-162g8z5 {
        width: 60px;
    }

    .App .container-select-sentiment,
    .sortable-item .container-select-sentiment {
        width: 32px;
    }

    .App .top-stories-item h3,
    .sortable-item .top-stories-item h3 {
        font-size: 0.55em;
    }

    .App .top-stories-item .result-body,
    .sortable-item .top-stories-item .result-body,
    .App .top-stories-item .result-url,
    .sortable-item .top-stories-item .result-url {
        font-size: 0.6em;
    }

    .App .row-left {
        margin-right: -15px !important;
    }

    .App .top-stories-cont,
    .sortable-item .top-stories-cont {
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }

    .App .top-stories .col-5,
    .sortable-item .top-stories .col-5 {
        padding-right: 10px;
        padding-left: 10px;
    }

    .App .top-stories-item .result-body,
    .sortable-item .top-stories-item .result-body {
        margin-bottom: 0.4rem;
    }

    .App .top-stories-item .result-url,
    .sortable-item .top-stories-item .result-url {
        margin: 0px;
    }

    .App .top-stories-item h3,
    .sortable-item .top-stories-item h3 {
        padding: 2px 0px 0px 0px;
    }

    .App .top-stories .select-category-image,
    .sortable-item .top-stories .select-category-image {
        width: 100%;
        padding: 0px 15%;
        position: relative;
        right: -2px;
    }

    .App .top-stories-h,
    .sortable-item .top-stories-h {
        margin: 0px 0 10px 0;
        position: relative;
        right: 10px;
    }

    .App h3,
    .sortable-item h3 {
        font-size: 14px;
    }

    .result-url,
    .side-result-url {
        font-size: 12px;
    }

    .App .knowledge-graph, .App .related-searches {
        float: left;
        margin-right: 15px !important;
        margin-left: 15px !important;
        padding: 0;
        width: 92%;
        width: -webkit-fill-available;
    }

    .App .knowledge-graph .col-8 h2 {
        font-size: 26px;
    }

    .App .knowledge-graph .col-md-12 {
        font-size: 12px;
    }

    .result-score-editable {
        font-size: 24px !important;
        width: 60px;
    }

    .App .ranking-cards {
        padding-left: 15px;
        padding-right: 15px;
    }

    .App .top-stories-card .col-5,
    .sortable-item .top-stories-card .col-5 {
        padding-right: 10px;
        padding-left: 10px;
    }

    .paginate-btn {
        margin: 0px 0px 30px 0px;
    }

    .tooltipBody .col-12,
    .App .related-searches .alignWithIcon .tooltipBody .col-12 {
        padding-right: 0px;
        padding-left: 0px;
    }

    .App .related-searches .alignWithIcon .tooltipBody .row {
        margin-right: -15px;
        margin-left: -15px;
    }

    .save-dropdown-btn {
        width: 103%;
    }

    .save-dropdown-btn .dropdown-menu.show {
        width: 97.5%;
    }

    .save-split-caret.dropdown-toggle::after {
        right: 5px;
    }
}

@media (max-width: 450px) {
    .result-score-editable {
        width: 48px;
    }

    .sentiment-distribution canvas, .category-distribution canvas {
        margin-top: 8%;
    }

    .file-component {
        max-width: 430px;
        width: 100%;
    }


    .table-responsive tr td:first-of-type,
    .table-responsive tr th:first-of-type {
        padding-left: 1rem !important;
    }

    .knowledge-graph-details p {
        max-width: 78px;
    }
}

@media (max-height: 450px) {
    .change-password-cont {
        margin-top: -50px;
    }
}

@media (max-width: 360px) {
    .App .rank-select .css-1ep9fjw,
    .sortable-item .rank-select .css-1ep9fjw,
    .App .rank-select .css-1sjym57,
    .sortable-item .rank-select .css-1sjym57 {
        left: 0px;
    }

    .App .rank-select .css-10nd86i,
    .sortable-item .rank-select .css-10nd86i {
        margin-right: 0px;
    }
}

@media (max-width: 1200px) and (min-width: 990px) {
    .knowledge-graph-details p {
        max-width: 90px;
    }
}

@media (max-width: 1300px) and (min-width: 992px) {
    .stats-boxes.fixed .container {
        max-width: 1060px;
    }
}

@media (max-width: 1500px) and (min-width: 1201px) {
    .stats-boxes .category-distribution span {
        display: none;
    }
}

.custom-logo-header {
    height: 70px;
    object-fit: contain;
}

.custom-logo-login {
    max-width: 260px;
    object-fit: contain;
}

.standard-logo-header {
    height: 70px;
    margin-top: -7px;
    object-fit: contain;
}

.standard-logo-login {
    max-width: 260px;
    object-fit: contain;
}

.modal-schedule .schedule-name h4 {
    font-size: 1em;
    font-weight: 700;
}

.modal-schedule .schedule-name h5 {
    font-size: 1.6em;
    font-weight: 400;
}

.modal-schedule .modal-title {
    width: 100%;
    flex: 1;
    text-align: center;
    font-weight: 700;
}

.modal-schedule .btn-save-data {
    transition: all .5s ease-in-out;
}

.modal-schedule .btn-save-data, .modal-schedule .btn-save-data:hover, .modal-schedule .btn-save-data:active, .modal-schedule .btn-save-data:focus {
    background-color: #35A9E0 !important;
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
}

.modal-schedule .btn-save-data:disabled, .modal-schedule .btn-save-data[disabled] {
    background-color: #D4D4D4 !important;
    opacity: .8 !important;
}

.modal-schedule .btn-save-data:hover {
    opacity: .7;
}
