/*--------------------------------------------------------------
# RANKING
--------------------------------------------------------------*/

.App .ranking-cards {
  float: left;
  padding-right: 10px;
  padding-left: 0px;
}
.App .ranking {
  padding: 1rem 0;
  padding-left: 0.5%;
  position:relative;
}

.App .rank-select .css-10nd86i,
.sortable-item .rank-select .css-10nd86i {
  margin-right: 1px;
}

.App .row-left {
  margin-right: -5px !important;
}

.App .ranking-title {
  padding: 0px;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.App .ranking-title .col-6 {
  max-width: 49%;
}

.ranking .no-side-p,
.sortable-item .no-side-p,
.sortable-item .rank-select {
  padding-right: 0;
  padding-left: 0;
}

.select-sentiment-element {
  margin-right: 1px;
}

.ranking .result-height,
.sortable-item .result-height {
  min-height: 150px;
}

span.step-inner {
  border-radius: 1.5em;
  -moz-border-radius: 1.5em;
  -webkit-border-radius: 1.5em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.5em;
  text-align: center;
  width: 1.5em;
  height: 1.5em;
}

.select-sentiment.step.sentiment-container {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 1.9em;
 height: 1.9em;
 border-radius: 1.9em;
 margin-right: 4px;
 margin-top: 1.5em;
}

.select-sentiment.step.sentiment-container.sentiment-active {
  box-shadow: 1px 1px 8px 3px rgba(0,0,0,0.21);
}

.select-sentiment.step.sentiment-container.sentiment-disabled span {
  background-color: #ddd;
}

.ranking .selectpicker,
.sortable-item .selectpicker {
  position: absolute !important;
  bottom: 0;
  left: 50%;
  display: block !important;
  width: 0.5px !important;
  height: 100% !important;
  padding: 0 !important;
  opacity: 0 !important;
  border: none;
}

.ranking .btn-default,
.sortable-item .btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

/* RENUMBERING */

.App .col-sm-8 {
  /* Set "my-sec-counter" to 0 */
  counter-reset: my-sec-counter;
}
/*
.App .step::before {
  counter-increment: my-sec-counter;
  content: counter(my-sec-counter);
}
*/
.connectedSortable
  .result
  .card-body
  .row
  .col-xs-1
  .dropdown-menu
  > li
  > a
  .step::before {
  counter-increment: none;
}

.bg-catastrophic {
  background: #d0021b;
}

.bg-negative {
  background: #d0021b;
}

.bg-positive {
  background: #329844;
}

.bg-neutral {
  background: #f8bc1c;
}

.bg-unrelated {
  background: #9b9b9b;
}

span.bg-unselected {
  color: #ccc;
  border: 1px solid #ccc;
  background-color: #fff;
}

.no-btn-border .bootstrap-select .btn-default {
  border: none;
}

.result-body {
  font-size: 12px;
}

.result-score {
  font-size: 32px;
  font-weight: 700;
}

.result h3 a {
  color: #1a0dab;
}

.result-url,
.side-result-url {
  font-size: 14px;
  overflow: hidden;
  color: #006621;
  margin-bottom: 0;
}

.result-height button {
  border-color: transparent;
}

.btn-link, .btn-link:focus,
.btn-link:hover {
  color: #23527c;
  text-decoration: underline;
  background-color: transparent;
  border: none !important;
  outline: none !important;
  box-shadow: none;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.result {
  margin-bottom: 30px;
}

.result:hover,
.sortable-item .result:hover,
.sortable-item .result:focus,
.sortable-item .result:active {
  -webkit-box-shadow: 0px 0px 24px -1px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 24px -1px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 24px -1px rgba(0, 0, 0, 0.4);
}

body li {
  list-style-type: none !important;
}

.ranking ul {
  margin-top: 0;
  margin-bottom: 0rem;
  padding: 0px;
}

.sortable-item .no-side-p {
  text-align: center !important;
}

.select-category {
  width: 90%;
}

.button-sentiment {
  position: relative;
  right: 8px;
  top: 3px;
}

.dropdown-toggle.button-sentiment::after {
  position: absolute;
  top: 50%;
  right: -12px;
  margin-top: -5px;
  vertical-align: middle;
}

.dropdown-toggle::after {
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -2px;
  vertical-align: middle;
}

.filter-option img,
.sortable-item .filter-option img {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  text-align: left;
  padding: 6px 14px;
}

button.view-details {
  font-size: 12px;
  color: #337ab7;
}

button.view-details, button.view-details:hover, button.view-details:focus, button.view-details:hover {
  text-decoration: none;
}

.App .btn-secondary:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.App .btn-secondary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #ffffff;
  color: #337ab7;
  border-color: #337ab7;
}
.App .dropdown-toggle,
.sortable-item .dropdown-toggle {
  color: #999;
}

.App .dropdown-toggle:hover,
.sortable-item .dropdown-toggle:hover {
  background-color: transparent;
  color: #999;
}

.select-category img,
.sortable-item .select-category img {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  text-align: left;
  padding: 0px 2px;
}

.App .css-1aya2g8,
.sortable-item .css-1aya2g8 {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.App .css-d8oujb,
.sortable-item .css-d8oujb {
  width: 0px;
}

.App .css-1rtrksz,
.sortable-item .css-1rtrksz {
  height: 100px;
  display: block;
  position: relative;
  left: 10px;
}

.App .bootstrap-select,
.sortable-item .bootstrap-select {
  display: none;
}

.App .rank-select .css-1ep9fjw,
.sortable-item .rank-select .css-1ep9fjw,
.App .rank-select .css-1sjym57,
.sortable-item .rank-select .css-1sjym57 {
  padding: 0px;
  position: relative;
  bottom: 3px;
  right: 5px;
}

.App .css-2o5izw,
.sortable-item .css-2o5izw {
  border: none;
}

.App .rank-select .css-va7pk8,
.sortable-item .rank-select .css-va7pk8,
.App .rank-select .css-142w6gm,
.sortable-item .rank-select .css-142w6gm {
  width: 40px;
  overflow: visible;
  right: 8px;
}

.App .rank-select .css-1rtrksz,
.sortable-item .rank-select .css-1rtrksz {
  height: 50px;
}

.App .rank-select .css-1rtrksz,
.sortable-item .rank-select .css-1rtrksz {
  left: 5px;
}

.select-sentiment-container {
  display: block;
}

.App .container-select-sentiment,
.sortable-item .container-select-sentiment {
  display: block;
  width: 62px;
  height: 35px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
  pointer-events: unset !important;
  cursor: grabbing !important;
  cursor: -moz-grabbing !important;
  cursor: -webkit-grabbing !important;
}

.App .top-stories-h,
.sortable-item .top-stories-h {
  text-align: left;
  margin: 10px 3px;
}

.App .top-stories-item,
.sortable-item .top-stories-item {
  padding-right: 5px;
  padding-left: 5px;
}

.App .top-stories-item img,
.sortable-item .top-stories-item img {
  width: 100%;
  max-height: 60px;
  object-fit: cover;
}

.App .top-stories-item h3,
.sortable-item .top-stories-item h3 {
  font-size: 14px;
  line-height: 1.1;
  font-weight: normal;
  padding: 7px 0px 0px 0px;
  font-family: arial;
}

.App .top-stories .select-category-image,
.sortable-item .top-stories .select-category-image {
  width: 90%;
  padding: 0px 14%;
  position: relative;
  right: 1px;
}

.App .top-stories-item .result-body,
.sortable-item .top-stories-item .result-body {
  font-size: 10px;
}

.App .top-stories-item .result-url,
.sortable-item .top-stories-item .result-url {
  margin: 5px 0;
}

.App .top-stories-item .clamp-lines__button,
.sortable-item .top-stories-item .clamp-lines__button {
  display: none;
}

.App .col-top-stories,
.sortable-item .col-top-stories {
  padding-right: 0px;
  padding-left: 0px;
}

.App .top-stories-card .col-5,
.sortable-item .top-stories-card .col-5 {
  padding-right: 26px;
  padding-left: 26px;
}

/*
.top-stories-card .css-162g8z5 {
  background-color: transparent;
  border-color: transparent;
}
*/

.sortable-item h5 {
  font-size: 14px;
}

.App .css-ln5n5c,
.sortable-item .css-ln5n5c {
  width: 0px;
}
.App .top-stories-item .clamp-lines__button {
  display: none;
}

.App .related-searches {
  margin-bottom: 30px;
  display: table;
  padding-right: 0px;
  padding-left: 0px;
}

.App .related-searches h3 {
  text-align: left;
  margin-bottom: 20px;
}

.App .related-searches .col-sm-4 {
  float: left;
}

.App .related-searches a {
  text-align: left;
  color: #337ab7;
}

.App .related-searches a:hover {
  color: #23527c;
}

.App .related-searches div {
  padding-left: 0px;
  /*padding-right: 30px;*/
}

.App .border-left,
.sortable-item .border-left {
  border-left: 0.9px solid #dee2e6 !important;
}

.hide {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
}

.paginate-btn {
  margin: 20px 0px 50px 0px;
  font-size: 18px;
  background-color: #337ab7;
  border-color: #2e6da4;
  padding: 0.5rem 1.5rem;
  width: 100%;
}

.paginate-btn:hover {
  color: #fff;
  background-color: #2e6da4;
  border-color: #2e6da4;
}

.btn-load-more {
  margin-top: 5px;
}

.closed-state {
  margin: 20px 0px 25px 0px;
  display: none;
}

/*------------------MEDIA QUERIES------------------------- */

@media (max-width: 1920px) {
  .App .ranking-title #col-2 {
    margin-left: -1rem;
  }
}
@media (max-width: 1500px) {
  .App .ranking-title #col-2 {
    margin-left: -1.5rem;
  }
  .App .ranking-title #col-1 {
    margin-left: .5rem;
  }
}

@media (max-width: 1366px) {
  .App .ranking-title #col-2 {
    margin-left: -2.5rem;
  }
  .App .ranking-title #col-1 {
    margin-left: 1.5rem;
  }
}