.modal-text {
    margin: 20px 10px 30px 10px;
}

.header-title {
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    text-transform: capitalize;
    color: #212529;
}

.action-plan-modal-header, .action-plan-modal-body {
    padding: 30px 30px 0px 30px;
}

.header-items {
    width: 800px;
}

.action-plan-modal-body {
    max-height: calc(100vh - 210px);
    overflow-y: auto;
}

.action-plan-modal-button {
    width: 121px;
    height: 37px;
    left: 0px;
    top: 0px;
    border: 1px solid #CFE1EA;
    box-sizing: border-box;
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #324A97;
    background: #FFFF;
    flex-grow: 0 !important;
    box-shadow: none !important;
    outline: none !important;
}

.action-plan-modal-button:focus, .action-plan-modal-button:active{
    border: 1px solid #CFE1EA;
    background-color: #FFFF !important;
}

.action-plan-modal-button:hover {
    color: #324A97;
    background: #FFFF;
    border: 1px solid #CFE1EA !important;
}

.action-points {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #212529;
}

.action-points-number {
    font-weight: bold;
    font-size: 60px;
    line-height: 73px;
    text-align: center;
}

.search-data {
    font-size: 18px;
    line-height: 32px;
    align-items: center;
    color: #212529;
}
.close {
    color: #324A97;
    opacity: 1;
    font-size: 30px;
    font-weight: normal;
    line-height: normal;
}

.close:focus, .close:active, .close:hover {
    border-color: #FFFF !important;
}
.card-action {
    width: 100%;
    height: auto;
    border-radius: 15px;
    padding-bottom: 30px;
}

.issue-type {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
    height: 37px;
    left: calc(50% - 885px/2);
    top: calc(50% - 37px/2 + 10px);
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 20px 0px;
    cursor: pointer;
    color: #FFFF;
}

.issue-type span {
    color: #FFFF;
    font-size: 18px;
    line-height: 150%;
    font-weight: bold;
}

.card hr {
    color:  #D4D4D4;
    margin: 0px;
}

.card-action-header {
    padding: 30px 0px 16px 0px;
    margin-bottom: 0;
    background-color: #FFFF;
    border-bottom: none;
}

.card-title, .critical-found-issue, .opportunity-found-issue, .warning-found-issue {
    font-weight: 600;
    font-size: 24px;
    line-height: 141%;
}

.card-action-header h3 {
    font-weight: 400;
    font-size: 24px;
    padding: 0px;
}

.card-action-body {
    padding-bottom: 10px;
}

.card-action-body .url {
    font-size: 14px;
    line-height: 32px;
    color: #21374A;
    font-weight: normal;
    padding-top: 16px;
}

.card-subtitle {
    padding: 0px;
    margin: 0px;
}

.card-action-body {
    padding: 0px 30px;
}

.card-action-body .result-title {
    font-size: 20px;
    line-height: 32px;
    color: #1A0DAB;
    font-weight: normal;
}

.card-action-body ol {
    padding-left: 18px;
}

.card-action-body li {
    list-style-type: decimal !important;
}

.importance, .action-items {
    font-weight: bold;
    font-size: 16px;
    line-height: 151.5%;
    color: #212529;
    margin: 0px;
    padding-top: 16px;
    text-align: left;
}

.footer span {
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #324A97;
}

.footer-contact {
    font-weight: 500;
    font-size: 20px;
    line-height: 141%;
    color: #212529;
}

.critical-found {
    color: #D0021B;
}

.critical-type {
    background: #D0021B;
}

.opportunity-found {
    color: #329844;
}

.opportunity-type {
    background: #329844;
}

.warning-found {
    color: #EDAC00;
}

.warning-type {
    background: #EDAC00;
}