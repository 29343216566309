.spinner-loading{
  display: flex;
  margin-top: 14px;
  justify-content:center;
}

.top-story-spinner {
  margin-left:2.72rem;
}

@media (max-width: 567px) {
  .spinner-loading{
    margin-top: 6px;
  }
}

.failed {
  color: #d0021b;
}

.result-score-timeout {
  color: #9e9e9e;
  font-size: 12px;
  padding-top: 50px;
  font-weight: 500;
}

.result-score-editable {
  font-size: 32px !important;
  border: none;
  width: 90px;
  text-align: center;
  padding: 0px !important;
}

.manually-enabled {
  border: 0px solid #337ab7;
}

.controlled_tag_col {
  background-color: #f8f8f8;
  padding: 5px 15px 0px 15px;
}

.controlled_tag.input-group {
  font-size: 13px;
  width: auto;
}

.controlled_tag.input-group label {
  display: flex;
  align-items: center;
}

.controlled_tag.input-group .input-check {
  position: relative;
  margin: 0 .5rem;
}

.controlled_tag.input-group .form-check-input:after {
  display: block;
}

body .card-body, .sortable-item .card-body {
  padding-bottom: 0px;
}

.automation_dropdown {
  padding: 0px;
}

.automation_dropdown .DropdownMenu{
  width: 80%;
  display: inline-block;
  float: left;
}

.popover_overrides{
  display: inline-block;
  float: right;
  width: 37px;
}

.custom.popover_overrides{
  display: block;
  width: 100%;
}

.saved_message_overrides{
  display: block;
  width: 100%;
}

.automation_sentiment{
  display:inline-block;
  width: 60%;
  float: left;
}

.automation_sentiment.block{
  width: 100%;
  margin-right: 50% !important;
  display: block;
}

/* // Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {

  .DropdownMenu{
    width: 100% !important;
    float: none !important;
    display: block !important;
  }

  .DropdownMenu span{
    font-size: 10px;
  }

  .automation_sentiment{
    display: block;
    width: 100%;
    padding-bottom: 10px;
  }

  .confirmation_category_buttons{
    padding: 0px;
    margin: 0px;
    width: 100%;
  }

  .confirmation_category_buttons .row{
    padding: 0px;
    margin: 0px;
    width: 100%;
  }

  .confirmation_category_buttons .row .col-md-2{
    float: right;
    width: auto;
    display: inline-block;
    padding: 0px;
  }

  .confirmation_category_buttons .row img{
    width: 12px;
  }

  .confirmation_sentiment_buttons .row{
    padding: 0px !important;
    margin: 0px !important;
    display: block;
  }

  .confirmation_sentiment_buttons .row .col-md-6{
    float: left;
    width: auto;
    display: inline-block;
    padding: 0px;
  }

  .confirmation_sentiment_buttons .row img{
    width: 12px;
  }

  .doneMessage{
    font-size: 12px;
  }
}

.doneMessage{
  display: block;
  width: 100%;
  font-size: 12px;
}

.DropdownMenu div:first-child div:first-child{
  border-radius: 0px;
  font-weight: bold;
}


.DropdownMenu.block{
  display: block;
  width: 100%;
}

.hidePopover{
  display: none;
}

.spinner-loading.tag-controlled{
  margin-top: 0px;
  justify-content: flex-end;
}

.spinner-loading.tag-controlled .result-score{
  font-size: inherit;
  font-weight: inherit;
}

@media (max-width: 1500px) {
  .automation_sentiment div:first-child{
    margin-top: 3.4px;
  }
}

button > * {
  pointer-events: none;
}
