/*--------------------------------------------------------------
# HEADER
--------------------------------------------------------------*/

.header-area {
  -webkit-box-shadow: 0px 6px 17px -11px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 6px 17px -11px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 6px 17px -11px rgba(0, 0, 0, 0.5);
  background-color: #fff;
}

.header-area .top-row {
  margin: 0 30px
}

.header-area .header-container {
  padding: 10px 0px 0px 0px;
}

.header-area .container img {
  display: block;
}

.header-area .btn-secondary {
  background-color: #fff;
  padding: 10px 16px;
}

.header-area .fa-search {
  content: " ";
  background-image: url(./../images/search-icon.svg);
  background-size: cover;
  display: block;
  height: 18px;
  width: 18px;
}

.header-controls{
  margin: 0 30px;
}

.header-area.fixed .header-controls {
  display: none
}

.header-area .header-buttons .save-dropdown-btn .btn.btn-primary.dropdown-save-as{
  width: 69%
}
.header-area .header-buttons .save-dropdown-btn button.btn.btn-block.btn-lg.save-split-btn{
  min-width: 105px
}

.header-area .header-buttons.hide-buttons-header {
  display: none
}

.header-area .location-container {
  height: 80px
}

.header-area .row-sub-search .col-sub-search {
  margin-top: 20px
}

.header-input-group .col-automation{
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
    align-items: center
}
.header-area .col-automation .btn-secondary{
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  margin-left: 15px;
}
.header-area .col-automation .btn-secondary:hover {
  background-color: #6c757d;
  opacity: 0.8
}
.header-area .row-sub-search .header-buttons .btn-save{
  min-width: 10%;
  width: 100%;
  font-size: 1rem;
  background-color: #007bff;
  border-color: #007bff
}
.header-area .row-sub-search .header-buttons .btn-export{
  min-width: 10%;
  width: 100%;
  font-size: 1rem;
  background-color: #dc3545;
  border-color: #dc3545
}


.header-area .fa-search:before {
  content: "";
}

.header-area input {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.header-area input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999;
  opacity: 1;
  /* Firefox */
}

.header-area input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #999;
}

.header-area input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #999;
}

.header-area .btn-secondary:hover {
  background-color: #e6e6e6;
}

.header-area .btn-secondary {
  border-color: #ced4da;
}


body .header-area .btn-primary:not(:disabled):not(.disabled).active {
  font-size: 18px;
  background-color: #337ab7;
  border-color: #2e6da4;
}

body .header-area .btn-danger:not(:disabled):not(.disabled).active {
  font-size: 18px;
}

.App .header-area .btn-danger.btn-secondary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.input-header .svg-inline--fa.fa-w-11 {
  width: 18px;
  height: 19px;
  font-size: 30px;
}

.divider {
  display: block;
  font-size: 0.875em;
  margin-top: 1em;
  margin-bottom: 1em;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 1;
}

.divider:before {
  width: 40px;
  height: 10px;
  background-color: #fff;
  content: "";
  margin: -5px 0 0 -20px;
  left: 50%;
  position: absolute;
  top: 50%;
  z-index: -1;
}

.divider:after {
  border-bottom: 1px solid #dfe0e6;
  content: "";
  display: block;
  position: absolute;
  top: 49%;
  width: 100%;
  z-index: -2;
}

.error-message {
  margin-top: 10px;
}

.subsearch-warning {
  text-align: left;
  padding-left: 1rem;
  font-size: 12px;
}
.save-warning {
  font-size: 12px;
  width: 200px;
  margin-right: 20px;
  display: block;
}
.exportFileMessage {
  font-size: 12px;
  width: 100%;
  min-width: 90px;
  padding: 0px;
  margin: 0px;
  display: block;
  margin-bottom: 0px;
  text-align: center;
}
.button-header .btn-danger {
  padding: 10px 16px;
}
.warning-empty-cache {
  margin-top:20px;
}
.clear-search, .clear-search:hover{
  color: #000;
}

.link-open-historic-modal {
  width: 170px;
  float: right;
  cursor: pointer;
  position: absolute;
  right: 0;
}

.link-open-historic-modal p{
  float: right;
  color: #3e6cb9;
}

.link-historical-disabled p{
  color: #999;
  cursor: progress !important;
}

.big_number {
  font-size:47px!important;
}

.logo-header-app {
  cursor: pointer;
}

@media (max-width: 992px){
  .link-open-historic-modal {
    right: 20px;
    bottom: 25px;
  }
}


@media (max-width:1500px) and (min-width: 1200px){
  .big_number {
    font-size: 41px!important;
  }
}

.search_page_spinner.search_page{
  position: absolute;
  top: 50%;
  left: 50%;
}

.alert.header-error-message{
  margin-bottom: -20px;
}

.onScrollHeader{
  position:fixed;
  top:0;
  text-align: left;
}