/*--------------------------------------------------------------
# IOS MODAL
--------------------------------------------------------------*/

.ios-modal,
.ios-send-button {
  background-color: #337ab7;
  border-color: #2e6da4;
  float: right;
  margin-top: 10px;
}

.ios-modal td,
.ios-modal tr,
.ios-modal th,
.ios-modal .table-bordered {
  border: 0px solid #fff !important;
}

.ios-modal th {
  font-weight: 500;
  padding-bottom: 20px;
}

.ios-modal td {
  padding-bottom: 30px !important;
  padding-top: 20px !important;
}

.ios-send-button {
  padding: 8px 30px;
  display: block;
  width: 100%;
  font-size: 18px;
  margin-top: 0px;
}

.ios-close-button {
  background-color: #fff;
  border-color: #fff;
  border-radius: 25px;
  float: right;
  position: absolute;
  right: 10px;
}

.ios-close-button:hover {
  background-color: #fff;
  border-color: #fff;
}

.btn-secondary.ios-close-button:not(:disabled):not(.disabled):active svg {
  color: #fff;
}

.btn-secondary.ios-close-button:not(:disabled):not(.disabled):active {
  background-color: #337ab7;
  border-color: #337ab7;
}

.alert-danger {
  margin-bottom: 20px;
}

.ios-message {
  padding-top: 10px;
  font-weight: bold;
}

.ios-label-email {
  font-weight: 500;
  margin-bottom: 0.2rem;
}

.ios-send-button:hover {
  display: block;
  width: 100%;
  color: #fff;
  background-color: #2e6da4;
  border-color: #2e6da4;
}



.save-modal-message {
  margin-bottom: 0!important;
}

@media (min-width: 576px) {
  body .ios-modal {
    max-width: 900px;
    width: 450px;
    position: absolute;
    left: 50%;
    margin-left: -225px;
    margin-top: 50px;
  }
}

@media (max-width: 320px) {
  body .modal-title {
    font-size: 18px;
  }
}
