.autosave-modal .modal-body > div .graphic-card {
  padding-top: 0px;
}

.autosave-modal .modal-body {
  background-color: #fff!important;
}

.autosave-modal .modal-header {
  border: none;
}

.autosave-modal p.title{
  font-size: 13px;
  font-weight: bold;
}

.historic-dashboard-firstrow{
  max-width: 50%;
  margin: 0 auto !important;
  padding-top: 30px;
}

.autosave-modal .ios-close-button {
  top: 10px;
  right: 1px;
}

.autosave-modal .dashboard-button {
  color: #26292d;
  margin-left: 10px;
  font-size: 16px;
}

.historic-date-title {
  min-width: 278px;
  margin-top: -24px;
}

.historic-date-title p{
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.historic-date-title p.location-label {
  font-size: 14px;
}

.autosave-modal .inputs-section {
  float:right;
  width: 598px;
}

.autosave-modal .react-datepicker-wrapper {
  width: 170px;
}

.button-close-row {
  margin-bottom: 22px;
}

.autosave-modal .ios-close-button svg {
  color: #b9b9b9;
}

.save-modal-message {
  width: 97%;
  margin: 0 auto;
}

.autosave-modal .card-header {
  text-align: center;
}

@media (max-width: 2000px){
  .autosave-modal .dashboard-firstrow {
      width: 100%;
      max-width: 1260px;
      min-width: 936px;
      padding: 15px 30px 0px 30px;
  }
}
@media (min-width: 576px){
  body .modal-dialog.autosave-modal {
    width: 80%;
    max-width: 960px;
  }
  .autosave-modal .modal-body > div {
    width: 50%;
    float: left;
    padding-bottom: 5px;
  }

  .autosave-modal .modal-body > span {
    margin-bottom: 18px;
  }
  .autosave-modal .modal-body > div .graphic-card .card {
    max-width: 500px;
  }
}

@media (max-width:950px) {
  .autosave-modal .inputs-section{
    width: 9%;
  }
  .react-datepicker-popper {
    left:-17%!important;
  }
}

@media (max-width:949px) {
  .autosave-modal .modal-body > div {
    width: 90%;
  }
}

@media (max-width:1500px) and (min-width:950px) {
  .modal-dialog.files-modal.autosave-modal {
    width:100%;
  }
  .autosave-modal .inputs-section {
    width:97%;
  }
}

@media (max-width:1200px) {
  .autosave-modal .react-datepicker-wrapper{
    margin-right: 50px;
  }
}

@media (max-width: 768px){
  .autosave-modal .dashboard-firstrow {
    padding: 15px 15px 0px 15px;
    max-width: 100%;
    min-width: 100%;
  }
  .autosave-modal .inputs-section {
    width: 100%;
  }
  .autosave-modal .react-datepicker-wrapper {
    width: 100%;
  }
  .autosave-modal .modal-body > div {
    width: 100%;
  }

}
