.App .knowledge-graph {
  float: right;
}

.row-with-border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

.App .knowledge-graph .col-4 {
  padding: 0px;
}

.App .knowledge-graph .col-8 h2 {
  text-align: left;
  font-size: 30px;
  margin-bottom: 0px;
  line-height: normal;
}

.App .knowledge-graph .col-8 p {
  text-align: left;
  color: #777;
  font-size: 13px;
  margin-bottom: 0px;
}

.App .knowledge-graph .col-4 img {
  vertical-align: middle;
  border-style: none;
  width: 100%;
  max-width: 150px;
  float: right;
  height: 100%;
  min-height: 100%;
  object-fit: contain;
  padding: 10px 15px 10px 0px;
}

.App .knowledge-graph .col-md-12 {
  text-align: left;
  font-size: 13px;
}

.App .knowledge-graph-details {
  padding-bottom: 15px;
}

.App .knowledge-graph-details div {
  margin: 5px 0;
}

.App .knowledge-description {
  padding-top: 15px;
}

.App .knowledge-graph .col-8 {
  position: relative;
  min-height: 120px;
}

.App .knowledge-name {
  position: inherit;
  padding: 10px 0px;
  top: 50%;
  transform: translateY(-50%);
}

.App .knowledge-description a,
.App .knowledge-graph-details a {
  color: #337ab7;
}

.App .knowledge-description a:hover,
.App .knowledge-graph-details a:hover {
  color: #23527c;
}

.icon-knowledge-graph-loading {
  display: flex;
  height: inherit;
  padding: 38% 0;
  justify-content:center;
}

.App .knowledge-graph {
  min-height: 137px;
}
.App .knowledge-graph.error {
  min-height: 0;
} 

.knowledge-graph-details .viewMore {
  font-size: 13px;
  float: left;
  width: 100%;
}

.knowledge-graph-details h4 {
  margin-top:25px;
}

.knowledge-graph-details a {
  float: left;
  padding: 1px;
  cursor: pointer;
  display: block;
  margin: 0 auto;
}

.knowledge-graph-details img {
  margin-top: 10px;
  display: block;
  margin: 0 auto;
}

.knowledge-graph-details p {
  text-align: center;
  margin-top: 5px;
  max-width: 90px;
}

.knowledge-graph-empty-separator {
  height:200px;
}

.people-also-search-item{
  display: inline-flex;
  width:25%;
}
@keyframes rotates {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotates {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
