.action-plan-badge {
    padding-right: 0px;
    padding-left: 20px;
}

.action-header {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    border-radius: 10px 10px 0px 0px;
    width: 100%;
    height: 60px;
    background: #0786CD;
    margin-top: 45px;
    padding: 20px 30px;
    cursor: pointer;
}

.action-toggle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
}

.badge {
    background-color: #ffff;
    color: #324A97;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
}

.card {
    height: 460px;
    padding: 0px 28px;
}

.card.advertisement {
    height: unset !important
}

.action-progress {
    width: 105px;
    height: 5px;
}

.card-title {
    font-weight: 600;
    font-size: 24px !important;
    text-align: left;
    line-height: 29px;
    color: #212529;
}

.card-content {
    font-size: 14px;
    line-height: 161.69%;
    color: #212529;
    text-align: left;
}

.card-list ul {
    padding: 0px 16px;
}

.card-list ul li {
    list-style-type: disc !important;
    font-weight: bold;
    font-size: 14px;
    line-height: 161.69%;
    text-align: left;
}

.card-content-bottom {
    text-align: left;
}

.action-plan-button {
    outline: none !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    padding: 10px 15px;
    width: 100%;
    height: 43px;
    left: 0px;
    top: 223px;
    border: 1px solid #CFE1EA;
    box-sizing: border-box;
    border-radius: 5px;
    margin: 23px 0px;
    color: #324A97;
    flex-grow: 0 !important;
    box-shadow: none !important;
}

.action-plan-button:hover {
    background: #FFFFFF;
    color: #324A97;
}

.action-plan-button:focus,
.action-plan-button:active {
    border: 1px solid #324A97;
}

.action-plan-button:focus,
.action-plan-button:hover {
    border: 1px solid #CFE1EA;
}

.action-plan-button:disabled {
    border: 1px solid #EEEDED;
    color: #EEEDED;
    background: #FFFFFF;
}

.save-warning-plan {
    width: 100%;
    text-align: center;
    font-size: 12px;
    margin: -15px 0 10px 0;
}

.badge-card {
    height: auto;
}


@media (max-width: 1200px) {
    .action-toggle {
        font-size: 13px;
    }

    .action-header {
        align-items: center;
    }
}