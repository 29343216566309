.OverridesPage{
    padding: 0 2.5%;
}
.OverridesPage .header-overrides {
    display: flex;
    justify-content: space-between;
    padding: 13px 10px;
    background-color: #f9f9f9;
    border-top: 2px solid #dee2e6;
    margin-top: 10px;
}

.OverridesPage  .left-side{
    width: 50%;
    display: flex;
    justify-content: flex-start;
}

.OverridesPage .header-overrides .search-input {
    width: 100%;
    margin-left: 10px;
}

.header-overrides .btn-new-override {
    margin-right: 20px;
    border: 1px solid #d4e2ec;
    color: #000;
    background-color: #fff;
    font-weight: bold;
}

.header-overrides .btn-new-override img{
    width: 16px; 
    margin-right: 10px;
}


.OverridesPage .nav-tabs{
    display: flex;
    align-items: center;
    margin: 0 15px
}



.OverridesPage .item-nav {
    cursor: pointer;
    padding: 0px;
}
.OverridesPage .item-nav a.active{
    border-bottom: 1px solid #dee2e6;
}

.OverridesPage .row {
    margin-right: 0px; 
    margin-left: 0px;
}

.OverridesPage .container-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #dee2e6;
    padding: 2% 2%
    
}

.OverridesPage .container-search button {
    margin-left: 20px;
}
.OverridesPage .container-search button:first-child {
    margin-left: 0px;
}


.container.containerOverridePage{
    margin-top: 50px;
    background-color: #fff;
    border: 1px solid #dee2e6;
    padding-top: 0px !important;
    
}

.container.containerOverridePage .nav.nav-tabs{
    margin: 0px;
}

.container.containerOverridePage .search-group{
    padding: 20px 0px;
    align-items: center;
}

.container.containerOverridePage .nav-link{
    padding: 20px;
    border: 0px;
    border-radius: 0px;
    font-weight: bold;
    font-size: 18px;
    color: #d4d4d4;
}

.container.containerOverridePage .nav-link.disabled:hover{
    color: #d4d4d4;
}

.container.containerOverridePage .nav-link.active{
    border-top: 2px solid #35a9df;
    color: #4f4f4f;
}

.container.containerOverridePage .btn-all, .container.containerOverridePage .btn-all:focus{
    float: left;
    background-color: transparent;
    color: #4eb4e4;
    border: 0px;
    padding: 0px;
    outline: 0;
    margin: 0px 0px 0px 20px;
    font-size: 18px;
    box-shadow: none;
}

.search-term{
    float: left;
    margin: 0px 0px 0px 10px;
    font-size: 18px;
    border-left: 2px solid #4f4f4f;
    padding-left: 9px;
    color: #4f4f4f;
}

.OverridesList table thead tr{
    background-color: #f9f9f9;
    color: #4f4f4f;
}

td.pending_save{
    color: red;
}

.OverridesPage .empty_list{
    padding: 50px;
    display: block;
    font-size: 19px;
    font-weight: bold;
}

.OverridesPage .OverridesList .url_item{
    width: 90%;
    display: inline-block;
}