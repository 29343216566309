.PopUp .modal-content {
    border-radius: 20px;
    width: 890px;
    height: 540px;
}
.PopUp .input-group-text{
    background-color: #ffff;
    border: none;
}

.PopUp .container-check {
    display: flex;
    width: auto;
    justify-content: flex-start;
    align-items: center
}
.PopUp .container-check span {
    color: black;
    font-size: 13px;
    font-weight: 540;
}

.PopUp .badge {
    background-color: #ffff;
    color: #0786CD;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    padding: 5px 10px;
}

.PopUp .modal-title {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0px;
    color: #ffff;
    margin: 0px;
    text-transform: capitalize;
}

.PopUp .container-check input[type=checkbox] {
    border-color: black;
    width: 20px;
    height: 20px;
}

.PopUp .modal-body {
    background-color: #ffff !important;
    color: #212529;
    padding: 30px 40px;
    text-align: left;
}

.PopUp .body-bottom p {
    font-weight: 500;
    font-size: 16px;
    line-height: 134.19%;
    color: #0786CD;
    width: 359px;
    margin: 0px;
}

.PopUp .modal-body .body-title{
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #212529;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.PopUp .modal-body .body-content{
    width: 359px;
    left: 0px;
    top: 0px;
    font-weight: normal;
    font-size: 14px;
    line-height: 161.69%;
}

.PopUp .modal-body ul {
    padding: 0px 14px;
}
.PopUp .modal-body ul li {
    list-style-type: disc !important;
    font-weight: bold;
    font-size: 14px;
    line-height: 161.69%;
}

.PopUp .modal-header {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    padding: 20px 0px 20px 50px;
    background-color:#0786CD;
}

.PopUp .modal-footer {
    display: flex;
    justify-content: space-between;
    border-top: 0px;
    padding: 10px 40px 20px 40px;
}

.PopUp .modal-footer .btn {
    width: 11%;
    background-color: #35a9e0;
    border-color: #35a9e0;
    font-size: 13px;
    font-weight: 700;
    padding: 1%
}

.PopUp .custom-border-radius-header {
    border-top-left-radius: 20px ;
    border-top-right-radius: 20px;
}

@media (min-width: 576px){
    .PopUp.modal-dialog {
        max-width: 870px;
        margin: 13.75rem auto;
    }
}
