.dashboard-firstrow {
  padding-top: 30px;
}

.dashboard-charts {
  padding-bottom: 5%;
}

.graphic-card .card {
  display: block;
  width: 100%;
  max-width: 550px;
  padding: 0px;
  margin: auto;
  margin-top: 5% !important;
  -webkit-box-shadow: 0px 0px 24px -1px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 14px -1px rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  position: relative;
}

.graphic-card {
  width: 100%;
  max-width: 700px;
  padding-left: 0px;
  padding-top: 15px;
  padding-right: 0px;
  margin: auto;
}

.graphic-card.container {
  padding: .2rem 0;
}

.graphic-card .card-header, .graphic-card .card-footer, .graphic-card .card-body {
  background-color: #fff;
  border: 0;
}

.graphic-card .card-footer {
  padding: 0 15px 10% 15px;
}

.graphic-card .card-body {
  padding-bottom: unset;
  padding-top: unset;
}

.graphic-card .card-header {
  font-size: 22px;
  font-weight: 500;
  padding-top: 7%;
  padding-bottom: 0;
}

.dashboard-firstrow label {
  font-size: 16px;
  line-height: 2.5;
  font-weight: normal;
  font-family: arial;
  margin-left: 1%;
  margin-right: 1%;
}

.dashboard-component .table-responsive {
  height: 100vh;
  position: relative;
}

.dashboard-firstrow .dashboard-component {
  margin-top: 12px;
  margin-bottom: 10px;
}

.table-responsive {
  display: contents;
  height: 96px;
}

.dashboard-component .dashboard-select .css-va7pk8 {
  overflow: hidden;
}

.dashboard-component .dashboard-select {
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
  border: 1px solid #cacaca;
  background-color: #fff;
  border-radius: 5px;
  margin-top: -10px;
  width: 200px;
}

.dashboard-select .css-11unzgr {
  background-color: #4e4e4e;
  color: #fff;
  border-radius: 5px;
  width: 100%;
  position: relative;
  font-weight: 500;
  -webkit-box-shadow: 0 7px 14px 0 rgba(59, 65, 94, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  box-shadow: 0 7px 14px 0 rgba(59, 65, 94, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  text-align: left;
}

.dashboard-select .css-15k3avv {
  height: auto;
}

.css-11unzgr div {
  cursor: pointer;
}

.dashboard-select .css-z5z6cw, .dashboard-select .css-z5z6cw:hover, .dashboard-select .css-wqgs6e, .dashboard-select .css-v73v8k:active, .dashboard-select .css-z5z6cw:active, .dashboard-select .css-wqgs6e:active {
  background-color: #38a9e0;
}

.dashboard-select .css-va7pk8 {
  color: #4e4e4e;
}

.dashboard-select .css-162g8z5 {
  min-height: 34px;
  height: 34px;
  border: none;
  border-radius: 5px;
}

.dashboard-component .dashboard-select .css-1rtrksz {
  height: inherit;
  padding: 0;
}

.dashboard-component .dashboard-select .css-1aya2g8, .dashboard-component .dashboard-select .css-2o5izw {
  min-height: 35px;
  height: 35px;
  text-align: left;
}

.dashboard-button {
  background-size: 20px;
  display: block;
  background-repeat: no-repeat;
  background-position: left;
  background-position-x: 12px;
  background-position-y: 6px;
  background-color: #fff;
  float: right;
  border: 1px solid #cdcdcd;
  padding: 6px 10px;
  height: 40px;
  color: #38a9e0;
  border-radius: 5px;
  font-weight: 600;
  margin-left: 1%;
}

.enabled {
  background-color: #fff;
}

.disabled {
  background-color: #cdcdcd;
}

.dashboard-button:hover, .dashboard-button:focus, .dashboard-button:active, .dashboard-button:hover:target {
  text-decoration: none;
}

.dashboard-input {
  border: 1px solid #cdcdcd;
  line-height: 33px;
}

.react-datepicker-wrapper {
  height: 50px !important;
}

.alertContainer {
  margin: 1% 0 0 15%;
  width: 70%;
}

.dashboard-charts div {
  margin: auto;
}

.dashboard-firstrow {
  max-width: 50%;
  margin: 0 auto !important;
}

.icon-search-loading.terms-select-loading {
  position: relative;
  bottom: 20px;
}

.dashboard-firstrow .disabled {
  color: #848484;
}

@media(max-width:2000px) {
  .dashboard-firstrow {
    width: 100%;
    max-width: 1100px;
  }
  .dashboard-charts {
    max-width: 1170px;
    width: 100%;
    margin: 0 auto !important;
  }
}

@media(min-width:1501px)and (max-width:1583px) {
  .dashboard-firstrow {
    margin: 0 auto !important;
    max-width: 1090px;
  }
  .dashboard-charts {
    /* max-width: 100%; */
    margin: auto !important;
  }
}

@media(max-width:1500px) {
  .dashboard-firstrow {
    margin: 0 auto !important;
  }
  .dashboard-charts {
    /* max-width: 100%; */
    margin: auto !important;
  }
}

@media(max-width:1400px) {
  .dashboard-firstrow {
    margin: auto!important;
  }
  .dashboard-charts {
    max-width: 1000px;
    /* width: 100%; */
    margin: 0 auto !important;
  }
}

@media(max-width:1220px) {
  .dashboard-firstrow {
    margin: 0 auto !important;
  }
  .dashboard-charts {
    max-width: 100%;
    margin: 0 auto !important;
  }
}

@media(max-width: 1120px) {
  .dashboard-input {
    width: 190px;
  }
}

@media (max-width:1084px) {
  .dashboard-firstrow {
    max-width: 100%;
    margin: 0 auto;
  }
  .dashboard-firstrow label {
    margin-bottom: 0px;
    text-align: left;
    height: 35px;
  }
  .dashboard-input {
    width: inherit;
  }
  .dashboard-charts {
    max-width: 100%;
    margin: 0 auto;
  }
  .dashboard-component .dashboard-select {
    width: 180px;
  }
  .graphic-card {
    width: 100%;
    max-width: 730px;
  }
  .dashboard-firstrow label {
    margin-right: 0%;
  }
}

@media(max-width: 990px) {
  .dashboard-button {
    margin-left: 0%;
    margin-top: 5px;
  }
  .dashboard-firstrow {
    max-width: 50%;
    display: inline-grid;
    margin: 0 auto;
  }
  .dashboard-component .dashboard-select {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .dashboard-firstrow {
    max-width: 50%;
    margin: 0 auto;
  }
  .dashboard-charts {
    max-width: 58%;
    margin: 0 auto !important;
  }
  .graphic-card .card {
    width: 100%;
    max-width: 400px;
  }
}

@media (min-width: 601px) and (max-width:703px) {
  .dashboard-component .dashboard-select {
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    border: 1px solid #cacaca;
    background-color: #fff;
    border-radius: 5px;
    margin-top: -10px;
  }
  .dashboard-select .css-11unzgr {
    background-color: #4e4e4e;
    color: #fff;
    border-radius: 5px;
    width: 100% !important;
    position: relative;
    font-weight: 500;
    -webkit-box-shadow: 0 7px 14px 0 rgba(59, 65, 94, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
    box-shadow: 0 7px 14px 0 rgba(59, 65, 94, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
    text-align: left;
  }
  .dashboard-firstrow {
    margin: 0 auto !important;
  }
  .dashboard-charts {
    max-width: 100%;
    margin: 0 auto !important;
  }
  .css-10nd86i .dashboard-select {
    margin-bottom: 13px;
  }
}

@media (max-width: 600px) {
  .dashboard-firstrow {
    max-width: 250px;
    margin: 0 auto !important;
  }
  .dashboard-charts {
    max-width: 100%;
    margin: 0 auto !important;
  }
  .App .dashboard-firstrow .css-1ep9fjw, .sortable-item .css-1ep9fjw, .App .dashboard-firstrow .css-1sjym57, .sortable-item .css-1sjym57 {
    padding: 0px !important;
    position: relative;
    left: 0px;
  }
}

@media(max-width: 500px) {
  .graphic-card {
    width: 100%;
    max-width: 335px;
  }
}

/*
@media (min-width: 400px) and (max-width: 450px) {
  .dashboard-component .dashboard-select {
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    width: 200px !important;
    border: 1px solid #cacaca;
    background-color: #fff;
    border-radius: 5px;
    margin-top: -10px;
  }
  .dashboard-select .css-11unzgr {
    background-color: #4e4e4e;
    color: #fff;
    border-radius: 5px;
    width: 200px !important;
    position: relative;
    font-weight: 500;
    -webkit-box-shadow: 0 7px 14px 0 rgba(59, 65, 94, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
    box-shadow: 0 7px 14px 0 rgba(59, 65, 94, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
    text-align: left;
  }
  .dashboard-firstrow {
    max-width: 60%;
    margin: 0 auto!important;
  }
  .dashboard-charts {
    max-width: 5%;
    margin: 0 !important;
  }
}*/

/*
@media(max-width: 360px) {
  .dashboard-component .dashboard-select {
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    width: 150px !important;
    border: 1px solid #cacaca;
    background-color: #fff;
    border-radius: 5px;
    margin-top: -10px;
  }
  .dashboard-select .css-11unzgr {
    background-color: #4e4e4e;
    color: #fff;
    border-radius: 5px;
    width: 150px !important;
    position: relative;
    font-weight: 500;
    -webkit-box-shadow: 0 7px 14px 0 rgba(59, 65, 94, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
    box-shadow: 0 7px 14px 0 rgba(59, 65, 94, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
    text-align: left;
  }
  .dashboard-firstrow {
    max-width: 50%;
    margin: 0 auto !important;
  }
  .dashboard-charts {
    margin: 0 !important;
    max-width: 5% !important;
  }
}
*/