/* Lumentus score colors */
.low-value-score{
  color: #d0021b;
}

.medium-value-score{
  color: #f4c430;
}

.high-value-score{
  color: #909090;
}

.very-high-value-score{
  color: #329844;
}