/*--------------------------------------------------------------
# LOGIN MODULE STYLES
--------------------------------------------------------------*/

.form-login {
  width: 100%;
  max-width: 420px;
  padding: 15px;
  margin: auto;
  margin-top: 5% !important;
  -webkit-box-shadow: 0px 0px 24px -1px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 24px -1px rgba(0, 0, 0, 0.4);
  border-radius: 0.25rem;
}

.form-login h1 {
  font-size: 1.8rem;
  margin: 1rem 0;
  font-weight: 400;
}

.form-login span {
  color: red;
  font-size: 14px;
}

.error {
  border: 1px solid;
  border-color: red;
}

.form-login img {
  padding: 20px 0 30px 0;
}

.form-login a {
  color: #337ab7;
  display: block;
  margin: 1em 0;
}

.submit-btn {
  display: block;
  width: 100%;
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
  padding: 0.5rem 1rem;
  font-size: 1.12rem;
}

.submit-btn:hover {
  display: block;
  width: 100%;
  color: #fff;
  background-color: #2e6da4;
  border-color: #2e6da4;
  padding: 0.5rem 1rem;
}

.custom-checkbox {
  float: left;
  margin-bottom: 1.5rem !important;
  padding-left: 0.5rem;
}

.custom-control-label::before {
  background-color: #ffffff;
  border: #adb5bd solid 1px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #fff;
}

.form-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-group > input,
.form-group > label {
  height: 3.125rem;
  padding: 0.75rem;
}

.form-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: #777;
  pointer-events: none;
  cursor: text; /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
  text-align: left;
}

.form-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-group input:-ms-input-placeholder {
  color: transparent;
}

.form-group input::-ms-input-placeholder {
  color: transparent;
}

.form-group input::-moz-placeholder {
  color: transparent;
}

.form-group input::placeholder {
  color: transparent;
}

.form-group input:not(:placeholder-shown),
.form-group input:focus {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

.form-group input:not(:placeholder-shown) ~ label,
input:focus + label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #777;
}

.question-force-login {
  width: 40%;
  min-width: 530px;
  margin: 45px auto 0;
}
@media (max-width: 450px){
    .question-force-login {
      width: 90%;
      min-width: 200px;
      margin: 15px auto 0;
    }
    .question-force-login .modal-title{
      font-size: 18px;
    }
}

/* Fallback for Edge
  -------------------------------------------------- */
@supports (-ms-ime-align: auto) {
  .form-group > label {
    display: none;
  }
  .form-group input::-ms-input-placeholder {
    color: #777;
  }
}

/* Fallback for IE
  -------------------------------------------------- */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-group > label {
    display: none;
  }
  .form-group input:-ms-input-placeholder {
    color: #777;
  }
}

@media (max-height: 500px) {
  .login-page .form-login {
    margin-top: 2.5% !important;
    margin-bottom: 2.5%;
  }
  .form-login img {
    padding: 0px 0 10px 0;
    width: 60%;
  }
  .form-login a {
    margin-bottom: 0px;
    margin-top: 0.5em;
  }
}
