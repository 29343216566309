/*--------------------------------------------------------------
# MODAL
--------------------------------------------------------------*/

body .modal-content {
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

body .modal-title {
  font-size: 22px;
  font-weight: 400;
}

body .modal-body table {
  font-size: 14px;
}

body .related-searches a {
  display: block;
  font-size: 14px;
  line-height: 24px;
}

body .alert-success,
body .modal-body .small-table td,
body .modal-body .alert-success tbody {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

body .modal-body td {
  display: table-cell;
}

body .modal-body .table td {
  padding: 0.68rem;
}

body .modal-body .table > tbody > tr > td,
body .modal-body .table > tbody > tr > th,
body .modal-body .table > tfoot > tr > td,
body .modal-body .table > tfoot > tr > th,
body .modal-body .table > thead > tr > td,
body .modal-body .table > thead > tr > th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: middle;
  border-top: 1px solid #ddd;
}

body .modal-body .table {
  margin-bottom: 0px;
}

body .modal-body .table small {
  font-size: 85%;
}

body .modal-body .alert-success td,
body .modal-body .alert-success th {
  padding: 0.2rem !important;
  background-color: #dff0d8;
}

.icon-loading {
  display: block;
  width: 36px;
  height: 36px;
  margin-left: -18px;
  margin-top: -18px;
  margin: 0 auto;
}

.load-speed-link {
    color: #212529;
    padding-left: 5px;
    font-weight: 500;
    text-decoration: underline;
    font-weight: bold;
}
.load-speed-link:hover {
    color: #212529;
}



@media (max-height: 2160px) {
  .seo-modal {
    padding-top: 22%;
  }
}

@media (max-height: 1600px) {
  .seo-modal {
    padding-top: 16%;
  }
}

@media (max-height: 1200px) {
  .seo-modal {
    padding-top: 12%;
  }
}

@media (max-height: 1080px) {
  .seo-modal {
    padding-top: 7%;
  }
}

@media (max-height: 769px) {
  .seo-modal {
    padding-top: 0%;
  }
}

.seo-modal { 
  height: auto;
  padding: 0 !important;
  margin-bottom: 0 !important;
}

.seo-modal .modal-title {
  width: 100%;
  padding: 0;
}