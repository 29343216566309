.check {
  display: inline-block;
  color: #78b13f;
  margin-right: 9px;
  margin-left: -5px;
  font-size: 120%;
  margin-top:-5px;
}

.search-locations > button {
  white-space: normal !important;
}

.search-locations {
  max-height: 300px;
  overflow-y: scroll;
}

#location-holder {
  width: calc(24vw + 42px);
}

@media screen and (min-width: 1750px) {
  #location-holder {
    width: calc(17vw + 42px);
  }
}

.header-area.fixed #location-holder {
  display: none;
}
#location-holder {
  position: relative;
}
#location-holder .container-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);

}
#location-holder .dropdown-toggle::after {
  color: black ;
}

@media screen and (max-width: 2000px) {
  .dashboard-location {
    width: 100%;
    max-width: 1100px;
    margin: auto;
  }
}

input.location_input{
  border-left: 0px;
  padding-left: 0px;
}

.input_addon span{
  background-color: transparent;
}

.input_addon.loading span{
  background-color: #e9ecef;;
}

.clear_location_btn{
  padding: 0px !important;
  margin-top: -15rem;
  margin-left: 14.25rem;
  font-size: 15px;
  border: 0px;
  color: #35A9E0;
  background-color: transparent !important;
}

.clear_location_btn:hover{
  color: #35A9E0;
}

.clear_location_btn:focus{
  box-shadow: none;
}

.removed-loc-dash{
  margin-top: -7.5rem;
  margin-left: 12rem;
}

/*----------------------------------MEDIA QUERIES----------------------*/

@media (min-width:1440px){
  .clear_location_btn{
    margin-left: 15.5rem;
  }
  .removed-loc-dash{
    margin-left: 13rem;
  }
}
@media (min-width:1600px){
  .clear_location_btn{
    margin-left: 17.8rem;
  }
  .removed-loc-dash{
    margin-left: 15.5rem;
  }
}
@media (min-width:1920px){
  .clear_location_btn{
    margin-left: 14.25rem;
  }
  .removed-loc-dash{
    margin-left: 12rem;
  }
}