.MenuOptionsList{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.MenuOptionsList .btn-group{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center
}
.MenuOptionsList .btn-group button.btn{
    display: flex;
    justify-content: center;
    background-color: #ffff;
    width: 13%;
    border: none
}
.MenuOptionsList .btn-group .dropdown-menu button:first-child{
    color: #ffc107;
    font-weight: 500;
}
.MenuOptionsList .btn-group .dropdown-menu button:nth-child(2){
    color:#dc3545;
    font-weight: 500;
}

.MenuOptionsList img {
    width: 100%
}

.MenuOptionsList .btn-group button.btn{
    width: 30px;
}