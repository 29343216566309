.OverridesList .container-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #dee2e6;
    padding: 2% 2%
    
}
.OverridesList {
    margin-left: -2.5%;
    margin-right: -2.5%;
}

.OverridesList .container-search button {
    margin-left: 20px;
}
.OverridesList .container-search button:first-child {
    margin-left: 0px;
}

.OverridesList .search-input {
    width: 40%;
    margin-left: 10px;
}

.OverridesList .table-body {
    background-color: #ffff
}

.OverridesList table .col-header{
    cursor: pointer
}

.OverridesList table td:last-child, .OverridesList table th:last-child {
    width: 10%
}

.OverridesList table .cancelBtnOverrides{
    cursor: pointer;
    background-color: transparent;
    color: rgb(208, 2, 27);
    border: none;
    margin-top: 5px;
    text-decoration: underline;
    box-shadow: none;
    animation: 0.3s ease all;
}

.OverridesList table .cancelBtnOverrides:hover{
    color: rgb(176 2 24) !important;
}

.OverridesList table .cancelBtnOverrides:active{
    color: rgb(176 2 24) !important;
}

.OverridesList table tbody td{
    vertical-align: top !important;
}