.icon-search-loading {
	display: inline-block;
	text-align: center;
	height: 47px;
}

.main-search-loading{
	display: block;
	margin: 0 auto; 
	margin-top: 50px; 
	width: 5px;
}