.DropdownMenu{
   display: flex;
   margin-bottom: 10px
}

.DropdownMenu.align-left {
    justify-content: flex-start;
}
.DropdownMenu.align-center {
    justify-content: center;
}
.DropdownMenu.align-right {
    justify-content: flex-end;
}

