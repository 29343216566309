.tooltip-width {
  width: 370px !important;
}

.tooltip-body-width {
  width: 330px !important;
}
.seo-metric-title{
  width: fit-content;
  width: -moz-fit-content;
}
.icon {
  font-size: 15px;
  color: #888888;
}

.titleAlign {
  display: inline-block;
}

.App .rankingTitles {
  font-size: 14px;
}

@media (max-width: 568px) {
  .knowledgeGraphIcon {
    padding-right: 8px;
  }
}

.knowledgeGraphIcon {
  float: right;
  display: flex;
  color: black;
  margin: 0 -.25rem .5rem 0;
}

.alignWithIcon {
  display: inline;
}
.relatedSearchesAlign {
  float: left;
}

.stats-boxes.fixed .tooltipBody .row {
  float: left;
}

.relatedSearches {
  clear: both;
}

h5,
small {
  margin-right: 3px;
}

/* Firefox */
@-moz-document url-prefix() {
  .App .rankingTitles {
      font-size: 13px;
  }
}

h6 {
  font-size: 14px;
}

.tooltipBody {
  max-width: 220px;
  color: #212529;
  font-size: 13px;
  text-align: left;
}

.tooltipContent {
  margin-bottom: 0.6em;
}

.__react_component_tooltip.type-info {
  color: #212529;
  background-color: #ffffff !important;
  opacity: 1;
  -webkit-box-shadow: 0px 0px 15px -1px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 15px -1px rgba(0, 0, 0, 0.4);
}

.__react_component_tooltip.type-info.place-top:after {
  border-top-color: #ffffff !important;
  border-top-style: solid;
  border-top-width: 6px;
}

.__react_component_tooltip.type-info.place-bottom:after {
  border-bottom-color: #ffffff !important;
  border-bottom-style: solid;
  border-bottom-width: 6px;
}

.__react_component_tooltip.type-info.place-left:after {
  border-left-color: #ffffff !important;
  border-left-style: solid;
  border-left-width: 6px;
}

.__react_component_tooltip.type-info.place-right:after {
  border-right-color: #ffffff !important;
  border-right-style: solid;
  border-right-width: 6px;
}

.__react_component_tooltip.type-info.border.place-top:before {
  border-top: 8px solid #dee2e6;
}

.__react_component_tooltip.type-info.border.place-bottom:before {
  border-bottom: 8px solid #dee2e6;
}

.__react_component_tooltip {
  padding: 22px 22px;
}

.related-searches .alignWithIcon {
  position: relative;
  left: 5px;
  bottom: 2px;
}

.related-searches .alignWithIcon a {
  display: table;
  position: relative;
  top: 1px;
}

div.titleAlign {
  display: block;
  text-align: left;
  margin: 7px 0px;
}

.App .related-searches .alignWithIcon div {
  padding-left: 9px;
  padding-right: 9px;
}

.ranking-title .text-left {
  padding-right: 11px;
  padding-left: 15px;
}

.App .titleAlign .top-stories-h,
.sortable-item .titleAlign .top-stories-h {
  margin: 10px 4px 10px 0px;
}

.score-title-cont {
  margin-top: -2px;
}

.App .knowledge-graph-details .__react_component_tooltip.type-info,
.App .knowledge-graph-details .__react_component_tooltip.type-info div {
  margin: 0px 0;
}

.App .knowledge-graph-details .__react_component_tooltip.type-info .col-12 {
  padding-right: 0px;
  padding-left: 0px;
}

.App .knowledge-graph-details .knowledgeGraphIcon {
  float: right;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 8px;
  bottom: 5px;
}
