.not-found-page {
  margin-top: 15vh;
}

.error-code {
  color: #324a97;
  font-weight: 600;
  font-size: 10rem;
}

.error-code span {
  color: #35a9e0;
}

.not-found-page .btn-link:focus,
.not-found-page .btn-link:hover {
  color: #23527c;
  text-decoration: none;
  background-color: transparent;
}

.not-found-page .btn-primary:not(:disabled):not(.disabled).active {
  font-size: 18px;
  background-color: #337ab7;
  border-color: #2e6da4;
}

.not-found-page .lead {
  position: relative;
  bottom: 45px;
  color: #324a97;
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 0px !important;
}

@media (max-height: 500px) {
  .not-found-page {
    margin-top: 0vh;
  }
  .error-code {
    line-height: 1;
  }
  .not-found-page .lead {
    bottom: 10px;
  }
}
