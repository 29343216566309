/*--------------------------------------------------------------
# PROFILE STYLES
--------------------------------------------------------------*/

.change-password-form .card {
  display: block;
  width: 100%;
  max-width: 420px;
  padding: 0px;
  margin: auto;
  margin-top: 5% !important;
  -webkit-box-shadow: 0px 0px 24px -1px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 24px -1px rgba(0, 0, 0, 0.4);
  border-radius: 0.25rem;
}

.change-password-form {
  width: 100%;
  max-width: 700px;
  padding-left: 0px;
  padding-top: 15px;
  padding-right: 0px;
  margin: auto;
}

.change-password-form .card-header,
.change-password-form .card-footer,
.change-password-form .card-body {
  background-color: #f1f3f4;
  border: 0;
}

.change-password-form .card-footer {
  padding: 0 15px 10% 15px;
}

.change-password-form .card-body {
  padding-bottom: unset;
  padding-top: unset;
}

.change-password-form .card-header {
  font-size: 22px;
  font-weight: 500;
  padding-top: 7%;
  padding-bottom: 0;
}

